import React, {useState} from 'react';
import { Form, Button, Space } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { StepsTKG, ModalFormTKG, QuoteOverviewTKG, QuoteItineraryDetailsTKG, OtherInformationTKG, TermsAndConditionsTKG } from "tkg-composite-ui";

const CreateQuote = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };    
  const hideModal = () => {
    setIsModalOpen(false);
  };

  const steps = [
    {
        title: 'Overview',
        description: 'Description for Overview'
    },
    {
        title: 'Tour Itinerary Details',
        description: 'Description for Tour Itinerary Details',
    },
    {
        title: 'Other information',
        description: 'Description for Other information',
    },
    {
      title: 'Terms & Conditions',
      description: 'Description for Terms & Conditions',
    },
  ];
  const [current, setCurrent] = useState(0);
  const [itemsSteps, setItemsSteps] = useState(steps);

  const onChange = (value) => {
      console.log('onChange:', current);
      setCurrent(value);
  };
  const [id, setId] = React.useState(1);
  const [productTypeItem, setProductTypeItem] = React.useState({});
  const [data, setData] = React.useState({});
  
  const [formQuoteOverviewTKG] = Form.useForm();
  const [itemQuoteOverviewTKG, setItemQuoteOverviewTKG] = React.useState({});


  const [formQuoteItineraryDetailsTKG] = Form.useForm();
  const [itemsQuoteItineraryDetails, setItemsQuoteItineraryDetails] = React.useState([]);

  const [formOtherInformationTKG] = Form.useForm();
  const [itemsOtherInformationTKG, setItemsOtherInformationTKG] = React.useState([{}]);

  const [formTermsAndConditionsTKG] = Form.useForm();
  const [itemTermsAndConditionsTKG, setItemTermsAndConditionsTKG] = React.useState({});
  
  const defaultQuoteItineraryDetail = {
    day: 1
  };

  React.useEffect(() => {
    const productTypesData = [
      {id: 1, "name":"Tour","slug":"tour","type":"itinerary","schema":{"hasTransfersVia":true,"hasPickupTransfers":true,"hasAccommodation":true,
      "itineraryDetailFields":[{"type":"select","name":"Tour Guide","data":["Yes","No"]},{"type":"product","name":"Sightseeing/activity"},{"type":"daterange","name":"Test DateRange"}],
      "hasHighlight":true,"hasInclusions":true,"hasVideos":true,"hasTags":true,"hasPriceFrom":true,"hasUploadCarouselImages":true,"hasHomePageSection":true,"hasProductExclusiveTo":true,"hasTwoForOneDealsForCompany":true,"hasSpecialOfferFor":true,"hasIncludeTermsAndConditions":true,"hasIncludePrivacyPolicy":true,"hasSiteAppearanceLocation":true,"hasAgeRange":true,
      "overviewFields":[{"type":"daterange","name":"Test Duration"},{"type":"select","name":"Active Level","data":["Leisurely","Active","Challenging"]},{"type":"product","name":"Related Trip"},{"type":"text","name":"Operator"}]},
      priceTableConfigSchema:{"ignoreAutoBalance":true,"fields":[{"type":"text","name":"Tour Company","isSearchable":true},{"type":"text","name":"Country","isVisible":false,"isSearchable":true},{"type":"text","name":"Accommodation","isVisible":true},{"type":"text","name":"Group Size","isVisible":true},{"type":"text","name":"Tour Type","isVisible":true,"isSearchable":true}]}},
  {id: 2, "name":"Cruise","slug":"cruise","type":"itinerary","schema":{"hasAccommodation":true,"itineraryDetailFields":[{"type":"select","name":"Port Transfer","data":["Inclusion","Exclusion"]},{"type":"select","name":"Shore Excursion","data":["Inclusion","Exclusion"]}],"hasHighlight":true,"hasInclusions":true,"hasVideos":true,"overviewFields":[{"type":"text","name":"Duration"},{"type":"product","name":"Related Trip"}]},
  priceTableConfigSchema:{"fields":[{"type":"text","name":"Cruise Line","isVisible":true,"isSearchable":true},{"type":"text","name":"Country","isVisible":true},{"type":"text","name":"Ship Name","isVisible":true,"isSearchable":true},{"type":"text","name":"Ship Category","isVisible":true,"isSearchable":true},{"type":"text","name":"Cabin Type","isVisible":true,"isSearchable":true}]}},
  {id: 3, "name":"Accommodation","slug":"accommodation","type":"basic","schema":{"hasHighlight":true,"hasInclusions":true,"hasVideos":true,"overviewFields":[{"type":"text","name":"Accommodation Name"},{"type":"text","name":"Address"},{"type":"text","name":"Room Type"}]},
  priceTableConfigSchema:{}}
    ];

    const selectedProductTypeItem = productTypesData[0];
    const overview = { productType: selectedProductTypeItem.slug,  numberOfRooms: 0, inclusions: [{}], status: '0'};
    setItemQuoteOverviewTKG(overview);
    
    setData({productTypes: productTypesData, 
      inclusions: [
        {name: 'First-Class Service'},
        {name: 'Sightseeing'},
        {name: 'Accommodation'}
      ],
      titles: [
        //{ label: 'Title', value: '' },
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Mrs.', value: 'Mrs.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mstr.', value: 'Mstr.' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Prof.', value: 'Prof.' },
        { label: 'Sir', value: 'Sir' },
        { label: 'Lady', value: 'Lady' },
      ],
      countries: [ 'Australia', 'Vietnam' ],
      companies: [{"id":835,"text":"Leisurecom Group"},{"id":878,"text":"Innovative Travel Company"},{"id":885,"text":"Complete Business Travel"},{"id":918,"text":"Crooked Compass"},{"id":919,"text":"The Events Company"},{"id":928,"text":"Vinpearl Joint Stock Company \u2013 Vingroup JSC"},{"id":951,"text":"COMO Hotels"},{"id":1099,"text":"TONKIN CRUISES COMPANY LIMITED"},{"id":1790,"text":"Travco Travel Company of Egypt"},{"id":1817,"text":"hitcompany"},{"id":1822,"text":"CF Mekong (Compagnie Fluviale du Mekong)"},{"id":1836,"text":"Leisurecom"},{"id":1882,"text":"INCOMFRANCE"},{"id":1908,"text":"COMO Point Yamu Phuket"},{"id":2019,"text":"The Travel & Cruise Company"},{"id":2076,"text":"Compass Holidays"}],
      statuses: [
        { label: 'Draft', value: '0' },
        { label: 'Publish', value: '1' },
        { label: 'Expired', value: '2' },
      ],
      transferVias: [
        {label: 'Select Transfer Via', value: 'none' },
        {label: 'On Your Own Arrangement', value: 'OnYourOwnArrangement' }, 
        {label: 'Domestic Flight', value: 'Domestic' }, 
        {label: 'International Flight', value: 'International' }, 
        {label: 'Train', value: 'Train' }, 
        {label: 'Ferry', value: 'Ferry' }, 
        {label: 'Boat', value: 'Boat' }, 
        {label: 'Helicopter', value: 'Helicopter' }, 
        {label: 'Seaplane', value: 'Seaplane' }, 
        {label: 'PrivateJet', value: 'PrivateJet' }],
      pickupTransfers: [
        {label: 'None', value: 'none' }, 
        {label: 'Private Car', value: 'Private Car' }, 
        {label: 'Taxi', value: 'Taxi' }, 
        {label: 'Seat In Coach', value: 'Coach' }, 
        {label: 'Ferry', value: 'Ferry' }, 
        {label: 'Boat', value: 'Boat' }, 
        {label: 'Seaplane', value: 'Seaplane' }],
      tripOptionses: [
        {label: 'None', value: 'none' }, 
        {label: 'One-way', value: 'OneWay' }, 
        {label: 'Return', value: 'Return' }] 
    });

    setItemsQuoteItineraryDetails([defaultQuoteItineraryDetail]);
    setProductTypeItem(selectedProductTypeItem);
  }, []);

    
  const onClickBack = (event) => {
    switch(current) {
      case 1:
        if (itemsSteps.length === 4) {
          setItineraryDetailsData(-1);
        } else {
          setOtherInformationData(-1);
        }
        break;
      case 2:
        if (itemsSteps.length === 4) {
          setOtherInformationData(-1);
        } else {
          setTermsAndConditionsData(-1);
        }
      case 3:
        // save it
        setTermsAndConditionsData(-1);
        break;
    }
  };

  const onClickNext = async (event) => {
    switch(current) {
      case 0:
        setOverviewData(1);
        break;
      case 1:
        if (itemsSteps.length === 4) {
          setItineraryDetailsData(1);
        } else {
          setOtherInformationData(1);
        }
        break;
      case 2:
        if (itemsSteps.length === 4) {
          setOtherInformationData(1);
        } else {
          setTermsAndConditionsData(1);
        }
      case 3:
        // save it
        setTermsAndConditionsData(1);
        break;
    }
  };
  const setOverviewData = async (value) => {
    try {
      const quoteOverviewData = await formQuoteOverviewTKG.validateFields();
      setItemQuoteOverviewTKG(quoteOverviewData);
      console.log('form QuoteOverviewTKG quoteOverviewData', quoteOverviewData);
      debugger;
      const days = quoteOverviewData.startDateEndDate[1].diff(quoteOverviewData.startDateEndDate[0], 'day');
      if (days < itemsQuoteItineraryDetails.length - 1) {
        itemsQuoteItineraryDetails.splice(days - 1, itemsQuoteItineraryDetails.length - days);
      } 

      for (let index = 0; index < days + 1; index++) {
        let currentDate = quoteOverviewData.startDateEndDate[0].add(index, 'day');
        if (index > itemsQuoteItineraryDetails.length - 1) {
          itemsQuoteItineraryDetails.push({ day: index + 1, date: currentDate });
        } else {
          itemsQuoteItineraryDetails[index].date = currentDate;
        }
      }

      setCurrent(current + value);
    } catch (ex) {
      console.log('Validate QuoteOverviewTKG Failed:', ex);
    }
  }

  const setItineraryDetailsData = async (value) => {
    try {      
      const quoteTourItineraryDetailsData = await formQuoteItineraryDetailsTKG.validateFields();
      console.log('form quoteTourItineraryDetailsData', quoteTourItineraryDetailsData);
      debugger;
      setItemsQuoteItineraryDetails(quoteTourItineraryDetailsData.tourItineraryDetails);
      
      const days = itemQuoteOverviewTKG.startDateEndDate[1].diff(itemQuoteOverviewTKG.startDateEndDate[0], 'day');
      if (days !== quoteTourItineraryDetailsData.tourItineraryDetails.length - 1) {
        itemQuoteOverviewTKG.startDateEndDate[1] = itemQuoteOverviewTKG.startDateEndDate[0].add(quoteTourItineraryDetailsData.tourItineraryDetails.length - 1, 'day');
        itemQuoteOverviewTKG.endDate = itemQuoteOverviewTKG.startDateEndDate[1].format('YYYY-MM-DD');
        //itemQuoteOverviewTKG.startDateEndDate[1] = itemQuoteOverviewTKG.endDate;
      }
      // quoteTourItineraryDetailsData.tourItineraryDetails.forEach(item => {
      //   if (item.checkinCheckout && item.checkinCheckout.length === 2) {
      //     item.checkin = item.checkinCheckout[0].format("YYYY-MM-DD HH:mm");
      //     item.checkout = item.checkinCheckout[1].format("YYYY-MM-DD HH:mm");
      //   }
      // });
      
      setCurrent(current + value);
    } catch (ex) {
      console.log('Validate formQuoteItineraryDetailsTKG Failed:', ex);
    }
  }

  const setOtherInformationData = async (value) => {
    try {
      const otherInformationData = await formOtherInformationTKG.validateFields();
      console.log('form otherInformationData', otherInformationData);
      setItemsOtherInformationTKG(otherInformationData.otherInformations);
      setCurrent(current + value);
    } catch (ex) {
      console.log('Validate formOtherInformationTKG Failed:', ex);
    }
  }

  const setTermsAndConditionsData = async (value) => {
    try {
      const termsAndConditionsData = await formTermsAndConditionsTKG.validateFields();
      console.log('form termsAndConditionsData', termsAndConditionsData);
      setItemTermsAndConditionsTKG(termsAndConditionsData);
      if (value > 0) {
        //save data
      } else {
        setCurrent(current + value);
      }
    } catch (ex) {
      console.log('Validate formTermsAndConditionsTKG Failed:', ex);
    }
  }

  const onProductTypeChange = async(valueItem) => {
    //const hideDetails = ['accommodation', 'visa', 'transfer', 'insurance', 'flight', 'other-service'];
    
    const cloneSteps = [...steps];
    if (productTypeItem.type !== 'itinerary' && valueItem.type === 'itinerary') {
      setItineraryDetailsText(cloneSteps, valueItem.name);
      setItemsSteps(cloneSteps);
    } else if (productTypeItem.type === 'itinerary' && valueItem.type === 'itinerary') {
      setItineraryDetailsText(itemsSteps, valueItem.name);
    } else if (productTypeItem.type === 'itinerary' && valueItem.type !== 'itinerary') {
      itemsSteps.splice(1, 1);
    }

    setProductTypeItem(valueItem);
  };

  const setItineraryDetailsText = (items, name) => {
    const detailText = " Itinerary Details";
    const descriptionText = " Itinerary Description";
    items[1].title = name + detailText;
    items[1].description = name + descriptionText;
  }

  return (
    <> 
    <Button onClick={() => showModal()}>Create manual Quote</Button>
    <ModalFormTKG title='Create manual Quote' open={isModalOpen} onClose={hideModal} footer={<Space>
      { current > 0 && <Button type="primary" className="btnTKGAction" onClick={onClickBack} icon={<DoubleLeftOutlined/>} >Back</Button> }
      <Button type="primary" className="btnTKGAction" onClick={onClickNext} icon={<DoubleRightOutlined/>} > { current === itemsSteps.length - 1 ? 'Finish' : 'Next'} </Button>
      </Space>}>
      <StepsTKG current={current}
                  //onChange={onChange}
                  items={itemsSteps} ></StepsTKG>
      { current === 0 && 
      <QuoteOverviewTKG form={formQuoteOverviewTKG} item={itemQuoteOverviewTKG} productTypeItem={productTypeItem} data={data} onProductTypeChange={onProductTypeChange}></QuoteOverviewTKG>}
      { current === 1 && itemsSteps.length === 4 &&
      <QuoteItineraryDetailsTKG form={formQuoteItineraryDetailsTKG} formListName='tourItineraryDetails' items={itemsQuoteItineraryDetails} data={data} productTypeItem={productTypeItem}></QuoteItineraryDetailsTKG>}
      { current === itemsSteps.length - 2 && 
      <OtherInformationTKG form={formOtherInformationTKG} items={itemsOtherInformationTKG}></OtherInformationTKG>}
      { current === itemsSteps.length - 1 && 
      <TermsAndConditionsTKG form={formTermsAndConditionsTKG} item={itemTermsAndConditionsTKG}></TermsAndConditionsTKG>}
      
      </ModalFormTKG>
    </>
)};

export default CreateQuote;
