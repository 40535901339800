import React, { useState } from 'react';
import { Button, Divider, Drawer, Tooltip, Typography, Form } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { AdultTKG } from "tkg-composite-ui";
import CardTKG from '../../../components/Card';

const { Text } = Typography;

const Adult = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };
    const data = {
        titles: [
            { label: 'Mr.', value: 'Mr.' },
            { label: 'Mrs.', value: 'Mrs.' },
            { label: 'Ms.', value: 'Ms.' },
            { label: 'Mstr.', value: 'Mstr.' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Sir', value: 'Sir' },
            { label: 'Lady', value: 'Lady' },
        ],
        countries: [ 'Australia', 'Vietnam' ],
    };
    const [formAdultTKG] = Form.useForm();

    React.useEffect(() => {
        formAdultTKG.setFieldValue('adults', [{ title: '', firstName: '', surName: '', dateOfBirth: '', countryOfPassport: '' }]);
    }, [data]);

    return (
        <>
            <CardTKG title="Adult"
                extra={
                    <Tooltip title="View Code">
                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                    </Tooltip>
                }
            >
                <Form form={formAdultTKG} >
                    <AdultTKG form={formAdultTKG} itemName='adults' data={data} ></AdultTKG>
                </Form>
            </CardTKG>
            <Drawer
                title="View"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Adult Composite</b> <EditOutlined /></Divider>
                <Text code>Composite need use with useEffect</Text> <br />
                <Text code>setFieldValue (field name inside AdultTKG)</Text> <Text>title: ' ', firstName: ' ', surName: ' ', dateOfBirt: ' ', countryOfPassport: ' '</Text> <br />
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import { AdultTKG } from "tkg-composite-ui";;

    const data = {
        titles: [
            { label: 'Mr.', value: 'Mr.' },
            { label: 'Mrs.', value: 'Mrs.' },
            { label: 'Ms.', value: 'Ms.' },
            { label: 'Mstr.', value: 'Mstr.' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Sir', value: 'Sir' },
            { label: 'Lady', value: 'Lady' },
        ],
        countries: [ 'Australia', 'Vietnam' ],
    };

    const [formAdultTKG] = Form.useForm();

    React.useEffect(() => {
        formAdultTKG.setFieldValue('adults', [{title: '', firstName: '', surName: '', dateOfBirt: '', countryOfPassport: ''}]);
    }, [data]);

    return (
        <Form form={formAdultTKG} >
            <AdultTKG form={form} parentName={name} itemName='adults' data={data}></AdultTKG>
        </Form>

    )
    
    `}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default Adult;
