import './App.scss';
import { Layout, Drawer, ConfigProvider } from 'antd';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import LayoutHeader from './components/layouts/Header';
import LayoutSider from './components/layouts/Sidebar';

const { Content, Sider } = Layout;

function App() {

    const [keyItem, setKeyItem] = React.useState(1);
    const [visible, setVisible] = React.useState(false);
    const openDrawer = () => setVisible(!visible);

    const onChangeItem = async (index, item) => {
        setKeyItem(index);
    };
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Nunito Sans, sans-serif",
                    colorPrimary: '#0288d1',
                    colorBorder: '#e3e6ef',
                    borderRadius: 8,
                    colorTextPlaceholder: '#b7c2c5',
                    controlHeightLG: 42,
                    fontSizeLG: 14
                },
            }}
        >
            <Layout>
                <Drawer
                    title={false}
                    placement={'left'}
                    closable={false}
                    onClose={() => setVisible(false)}
                    open={visible}
                    key={'left'}
                    width={270}
                >
                    <Sider className="siderTablet" width={270}>
                        <LayoutSider
                            keyItem={keyItem}
                            onChangeItem={onChangeItem}
                        />
                    </Sider>
                </Drawer>
                <Sider className="sider" width={270}>
                    <LayoutSider
                        keyItem={keyItem}
                        onChangeItem={onChangeItem}
                    />
                </Sider>
                <Layout className="site-layout">
                    <LayoutHeader
                        openDrawer={openDrawer}
                        visible={visible}
                    />
                    <Content className="site-layout__content">
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </ConfigProvider>
    );
}

export default App;
