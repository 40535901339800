import { NavLink } from 'react-router-dom';

const menu = [
    {
        label: <NavLink to={'/started'}>Getting Started</NavLink>,
        key: 'started',
        icon: '',
    },
    {
        label: <NavLink to={'/simple'}>Simple Composite</NavLink>,
        key: 'simple',
        icon: '',
    },
    {
        label: <NavLink to={'/package'}>Package Composite</NavLink>,
        key: 'package',
        icon: '',
    },
    {
        label: <NavLink to={'/form-composite'}>Form Composite</NavLink>,
        key: 'form-composite',
        icon: '',
    }
];

export default menu;