import { Form, Button } from 'antd';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
//import { postCreateRole, putUpdateRole, Constant } from "@tkgplatform/api";
import { TabTKG, ModalFormTKG } from "tkg-composite-ui";
import Overview from './overview';
import ItineraryDetail from './itineraryDetail';
import './modalAdd.scss';

const ModalAdd = ({item, data, callback}) => {
  const [activeKey, setActiveKey] = useState('1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formOverview] = Form.useForm();
  const [formItineraryDetail] = Form.useForm();

  
  //console.log('ModalAdd product type data', data);
  const handleChangeType = async(value) => {
    if (value === 'basic') {
      setTabItems([tabItemsLayout[0]]);
    } else {
      setTabItems(tabItemsLayout);
    }
  };
  //console.log('Add Item data', data);
  const tabItemsLayout = [
    {
      key: '1',
      label: `Overview`,
      children: <Overview form={formOverview} item={item} data={data} onChangeType={handleChangeType} parentName='schema' callback={callback}></Overview>,
    },
    {
      key: '2',
      label: `Itinerary Details`,
      children: <ItineraryDetail form={formItineraryDetail} item={item} data={data} parentName='schema' callback={callback}></ItineraryDetail>,
    },
  ];
  const [tabItems, setTabItems] = useState(tabItemsLayout);

  const showModal = () => {
    setActiveKey('1');
    setIsModalOpen(true);
  };    
  const hideModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async() => {
    try {
      const overviewData = await formOverview.validateFields();
      const itineraryDetailData = await formItineraryDetail.validateFields();
      //console.log('overviewData itineraryDetailData', overviewData, itineraryDetailData);
      overviewData.schema = {...itineraryDetailData.schema, ...overviewData.schema}
      console.log('overviewData', overviewData);
      debugger;
    } catch(ex) {
      if (activeKey === '2') {
        setActiveKey('1');
      }
      console.log('Validate Failed:', ex);
    }
    // formOverview.validateFields().then((role) => {
    //     //form.resetFields();

    //     console.log('validateFields role', role);
    //     // if (!item) {
    //     //   postCreateRole(role).then(roleCallback);   
    //     // } else {
    //     //   role.id = item.id;
    //     //   putUpdateRole(role).then(roleCallback);   
    //     // }  
    //     // if (setIsLoading instanceof Function) {
    //     //   setIsLoading(false);
    //     // }  
    //   })
    //   .catch((info) => {
    //     // if (setIsLoading instanceof Function) {
    //     //   setIsLoading(false);
    //     // }
        
    //   });
    };

    const handleChangeTab = (key) => {
      setActiveKey(key);
    }
    useEffect(() => {
      handleChangeType(item.type);
    }, [item, data]);
    
    return (
      <div className='add-product-type'>     
        {!item.id ? <Button type="primary" onClick={showModal} className="btnTKGAction" icon={<PlusCircleOutlined/>}>Add New</Button> : 
                  <Button type="primary" onClick={showModal} title="Edit Product Type" className="btnTKGAction" shape='circle' icon={<EditOutlined/>}></Button>}  
          <ModalFormTKG title={!item.id ?'Add Product Type' : 'Edit Product Type'} open={isModalOpen} onClose={hideModal} 
            footer={<Button type="primary" className="btnTKGAction" onClick={onSubmit} > { !item.id ? 'Save' : 'Update'} </Button>} >
            <TabTKG activeKey={activeKey} items={tabItems} onChange={handleChangeTab}>                    
            </TabTKG>  
          </ModalFormTKG>
      </div>
    );
};
export default ModalAdd;