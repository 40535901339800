import React, { useState } from 'react';
import { Button, Card, Collapse, Divider, Drawer, Form, Tooltip, Row, Col, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { SelectTKG, TimePickerTKG } from "tkg-composite-ui";

const { Panel } = Collapse;

const { Text } = Typography;

const SimpleSelect = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };

    const [form] = Form.useForm();

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="Select Components" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Select Default"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                                    </Tooltip>
                                }
                            >
                                <Form
                                    name="basic"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    autoComplete="off"
                                    layout='vertical'
                                    form={form}
                                >
                                    <Form.Item name="textbox" label="Simple Select">
                                        <SelectTKG form={form} options={[{ value: 'Value A', label: 'Value A' }, { value: 'Value B', label: 'Value B' }]} />
                                    </Form.Item>

                                    <Form.Item name="textbox1" label="Simple Select | Compulsory" rules={[{ required: true, message: 'Please select !' }]}>
                                        <SelectTKG form={form} options={[{ value: 'Value A', label: 'Value A' }, { value: 'Value B', label: 'Value B' }]} />
                                    </Form.Item>
                                    <Form.Item name="textbox2" label="Time Picker">
                                        <TimePickerTKG name={'textbox3'} form={form} />
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title="Select"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Simple Select</b> <EditOutlined /></Divider>
                <Text code>size</Text> <Text>Sizes of an Select box ( default: large )</Text> <br />
                <Text code>options</Text> <Text>Options of an Select box ( default: {`[{value: 'select', label: 'Select'}]`} )</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import { SelectTKG } from "tkg-composite-ui";;

    <SelectTKG />
    `}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Simple Select | Compulsory</b> <EditOutlined /></Divider>
                <Text>To validate, the select needs to be put in the form</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout='vertical'
        form={form}
    >
        <Form.Item form={form} name="textbox" label="Simple Select | Compulsory" rules={[{ required: true, message: 'Please select !' }]}>
            <SelectTKG />
        </Form.Item>
    </Form>
`}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Time Picker</b> <EditOutlined /></Divider>
                <Text>Select Time Picker</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
        <Form.Item name="timepicker" label="Time Picker">
            <TimePickerTKG name={'textbox3'} form={form} />
        </Form.Item>
`}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default SimpleSelect;
