import React from 'react';
import { Form, Button } from 'antd';
import dayjs from 'dayjs'
import 'dayjs/locale/en-au';
import { AddContactCRMTKG } from "tkg-composite-ui";

const EditContactCRM = (props) => {
  const currentYear = dayjs().year();
  const data = {titles: [
    //{ label: 'Title', value: '' },
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mstr.', value: 'Mstr.' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Dr.', value: 'Dr.' },
    { label: 'Prof.', value: 'Prof.' },
    { label: 'Sir', value: 'Sir' },
    { label: 'Lady', value: 'Lady' },
  ], 
  countries: [ 'Australia', 'Vietnam' ],
  phoneCountryCodes: [
    { id: '84', name: '(+84) Vietnam' },
    { id: '61', name: '(+61) Australia' },
  ],
  genders: ['Male', 'Female', 'Other'],
  companies: [{"id":835,"text":"Leisurecom Group"},{"id":878,"text":"Innovative Travel Company"},{"id":885,"text":"Complete Business Travel"},{"id":918,"text":"Crooked Compass"},{"id":919,"text":"The Events Company"},{"id":928,"text":"Vinpearl Joint Stock Company \u2013 Vingroup JSC"},{"id":951,"text":"COMO Hotels"},{"id":1099,"text":"TONKIN CRUISES COMPANY LIMITED"},{"id":1790,"text":"Travco Travel Company of Egypt"},{"id":1817,"text":"hitcompany"},{"id":1822,"text":"CF Mekong (Compagnie Fluviale du Mekong)"},{"id":1836,"text":"Leisurecom"},{"id":1882,"text":"INCOMFRANCE"},{"id":1908,"text":"COMO Point Yamu Phuket"},{"id":2019,"text":"The Travel & Cruise Company"},{"id":2076,"text":"Compass Holidays"}],
  contactTypes: [
    { id: 16, name: "Employee"},
    { id: 1, name: "Online Travel Agent"},
    { id: 2, name: "Retail Travel Agent"},
    { id: 3, name: "B2B Login"},
    { id: 4, name: "Business Partner"},
    { id: 5, name: "Local Supplier"},
    { id: 6, name: "Distributor"},
    { id: 7, name: "Wholesaler"},
    { id: 8, name: "Tour Operator"},
    { id: 9, name: "Corporate"},
    { id: 10, name: "Cruise Line"},
    { id: 17, name: "Passenger"},
    { id: 18, name: "Accommodation"},
    { id: 19, name: "Airline"},
    { id: 11, name: "Other"},
  ],
  onlinePortalAccesses: [
    { id: 'ClientPortal', name: 'Client Portal' },
    { id: 'AgentPortal', name: 'Agent Portal' },
    { id: 'SupplierPortal', name: 'Supplier Portal' },
    { id: 'InvestorPortal', name: 'Investor Portal' },
  ],
  phoneOptions: [
    { id: '1', name: 'Work Phone' },
    { id: '2', name: 'Mobile' },
    { id: '3', name: 'Fax' },
    { id: '4', name: 'SMS Marketing' },
    { id: '5', name: 'Home Phone' },
  ],
  emailOptions: [
    { id: '1', name: 'Work' },
    { id: '2', name: 'Newsletter' },
    { id: '3', name: 'Personal' },
    { id: '4', name: 'Other' }
  ],
  preferredPostalAddresses: [
    { id: 'business_post', name: 'Business' },
    { id: 'postal_post', name: 'Postal' },
  ],
  subscribeToWebsites: [ 'Tweet World Travel B2B', 'Tweet World Vacation', 'TKG Platform' ],
  sources: [
    { id: 1, name: "Phone Call"},
    { id: 2, name: "Email"},
    { id: 3, name: "Website"},
    { id: 4, name: "Newsletter"},
    { id: 5, name: "Advertising"},
    { id: 6, name: "Google Search Engine"},
    { id: 7, name: "Roadshow/Exhibition"},
    { id: 8, name: "Existing Customer"},
    { id: 9, name: "By Recommendation"},
    { id: 10, name: "Reservation"},
    { id: 11, name: "Sales Boost Program"},
    { id: 12, name: "Online Sites"},
    { id: 13, name: "Supplier Register Tweet World Travel Website"},
    { id: 15, name: "B2B Portal"},
    { id: 14, name: "Other"},
  ],
  financialYears: [ currentYear - 2, currentYear - 1, currentYear, currentYear + 1 ],
  addBookingUrl: 'https://agentportal.tweetworldtravel.com/admin/booking/create?crmId=1130'
};
  const item = { id: 1, orderCodeLink: 'https://agentportal.tweetworldtravel.com/admin/quotes/TWT865134', 
    isLeader: false, dateOfBirth: '2017-02-27', bedding: 'test bedding', 
    title: 'Mr.', countryOfPassport: 'Vietnam', medical: 'test', firstName: 'Minh Long', surName: 'Tran', 
    email: 'pika2017@gmail.com', phoneCountryCode: '84', phoneNumber: '0986977632', contactTypeId: 16,
    emergencyPhoneCountryCode: '84', emergencyPhoneNumber: '0913761279', emergencyContactName: 'Quoc Huy Tran', financialYear: currentYear,
    subscribeToNewsletters: true, photo: [{
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    }],
    documents: [{
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    }],
    noteActivities: [
      { name: 'Robert Quoc', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc', date: '2023-03-06 5:22:22', body: 'abc'},
    ],
    activities: [
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-06 5:21:22', body: 'test'},
      { name: 'Robert Quoc - Edit Contact', date: '2023-03-06 5:22:22', body: 'abc'},
      { name: 'Robert Quoc - Note Contact', date: '2023-03-05 5:22:22', body: `<p>Change <span style='color: rgb(2, 136, 209);'>Title</span> from '' to <span style='color: red;'>'Mr'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Gender</span> from '' to <span style='color: red;'>'Select'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Surname</span> from 'Tran Quoc' to <span style='color: red;'>'Tran'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Department</span> from '' to <span style='color: red;'>'IT'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Country Code</span> from '' to <span style='color: red;'>'61'</span></p>
      <p>Change <span style='color: rgb(2, 136, 209);'>Phone Number</span> from '' to <span style='color: red;'>'468679345'</span></p>`},
    ],
  };

  // React.useEffect(() => {
  //   form.setFieldValue('adults', [{firstName: 'Huy test', dateOfBirth: '2017-02-27'},{}]);
  // }, [data]);

  return (
    <AddContactCRMTKG item={item} data={data} ></AddContactCRMTKG>
)};

export default EditContactCRM;
