import { Form, Row, Col } from 'antd';
//import { postCreateRole, putUpdateRole, Constant } from "@tkgplatform/api";
import { InputTKG, CheckboxTKG, SelectTKG, PopoverTKG, CollapseTKG, PanelTKG, AddDynamicFieldsTKG, SetSlug, SetNameForNestedForm } from "tkg-composite-ui";


const ItineraryDetail = ({form, item, data, parentName, onChangeType, callback}) => {

  return (
      <Form form={form} name="formItineraryDetail" layout="vertical" 
        initialValues={item}> 
        <Row gutter={[16]}>
          <Col span={24}>
            <CollapseTKG >
              <PanelTKG header="Default Fields" key="1" >
                <Row gutter={[16, 16]}>
                {data.itineraryDetailsDefaultFields?.map((field) => { 
                return (
                <Col key={field.name} span={6}>
                  <PopoverTKG title={field.description}>
                    <InputTKG form={form} value={field.name} readOnly={true} />
                    </PopoverTKG>
                </Col>)
                })}   
                </Row>
              </PanelTKG>
            </CollapseTKG>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasTransfersVia"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Transfers via?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasPickupTransfers"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Pick-up transfers?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasAccommodation"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Accommodation?</CheckboxTKG>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
              <AddDynamicFieldsTKG form={form} data={data} parentName={parentName} itemName='itineraryDetailFields'></AddDynamicFieldsTKG>
          </Col>
        </Row>
    </Form>
  );
};
export default ItineraryDetail;