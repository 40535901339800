import { useState, useEffect } from 'react';
import { Menu } from 'antd';
import React from 'react';
import menu from '../../router';

const LayoutSider = () => {
    const [current, setCurrent] = useState('');
    const onClick = (e) => {
        setCurrent(e.key);
    };
    useEffect(() => {
        if (window.location.pathname.replace('/', '') !== '') {
            return setCurrent(window.location.pathname.replace('/', ''));
        }
        return setCurrent('dashboard');
    }, []);

    return (
        <>
            <Menu
                defaultSelectedKeys={['1']}
                mode="inline"
                defaultOpenKeys={[current]}
                selectedKeys={[current]}
                items={menu}
                onClick={onClick}
            />
        </>
    );
};

export default LayoutSider;
