import React, { useState } from 'react';
import { Button, Card, Collapse, Divider, Drawer, Tooltip, Row, Col, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ModalFormTKG, ModalTKG } from 'tkg-composite-ui';

const { Panel } = Collapse;

const { Text } = Typography;

const ModalUI = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [viewCodeModal, setViewCodeModal] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const showCodeModal = () => {
        setViewCodeModal(true);
    }
    
    const onClose = () => {
        setOpenDrawer(false);
        setOpen(false);
    };
    const onCloseModal = () => {
        setViewCodeModal(false);
        setOpenModal(false);
    };
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const showDrawerct = () => {
        setOpen(true);
    };
    const showModal = () => {
        setOpenModal(true);
    };

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="Modal / Drawer" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card
                                title="Drawer"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                                    </Tooltip>
                                }
                            >
                                <Button type="primary" onClick={showDrawerct}>
                                    Click to show example Drawer
                                </Button>

                                <ModalFormTKG
                                    title="Modal Form Title !!!"
                                    footer={<Button type="primary" className="btnTKGAction" > Save</Button>}
                                    onClose={onClose}
                                    open={open}
                                >
                                    This is content inside Modal
                                </ModalFormTKG>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card
                                title="Modal"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCodeModal()} />
                                    </Tooltip>
                                }
                            >
                                <Button type="primary" onClick={showModal}>
                                    Click to show example Modal
                                </Button>

                                <ModalTKG
                                    title="Modal Form Title !!!"
                                    footer={<Button type="primary" className="btnTKGAction" > Save</Button>}
                                    onClose={onCloseModal}
                                    open={openModal}
                                >
                                    This is content inside Modal
                                </ModalTKG>
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title="View Code"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                {/* <Divider orientation="left" orientationMargin="0" plain><b>Props Custom</b> <EditOutlined /></Divider>
                <Text code>isHiddenIndex</Text> <Text>Hidden first column index number - Default (false)</Text> <br />
                <Text code>isShowedFullData</Text> <Text>Show pagination and quick search bar - Default (false) </Text> */}
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
        import {ModalFormTKG}  from 'tkg-composite-ui';

        <ModalFormTKG 
        title="Modal Form Title !!!"
        footer={<Button type="primary" className="btnTKGAction" > Save</Button>}
        onClose={onClose} 
        open={open}
        >
            This is content inside Drawer
        </ModalFormTKG>
    `}
                </SyntaxHighlighter>
            </Drawer>
            <Drawer
                title="View Code"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onCloseModal}
                open={viewCodeModal}
                extra={
                    <CloseOutlined onClick={onCloseModal} />
                }
            >
                {/* <Divider orientation="left" orientationMargin="0" plain><b>Props Custom</b> <EditOutlined /></Divider>
                <Text code>isHiddenIndex</Text> <Text>Hidden first column index number - Default (false)</Text> <br />
                <Text code>isShowedFullData</Text> <Text>Show pagination and quick search bar - Default (false) </Text> */}
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
        import {ModalTKG}  from 'tkg-composite-ui';

        <ModalTKG 
        title="Modal Form Title !!!"
        footer={<Button type="primary" className="btnTKGAction" > Save</Button>}
        onClose={onClose} 
        open={open}
        >
            This is content inside Modal
        </ModalTKG>
    `}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default ModalUI;
