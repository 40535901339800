import { Layout } from 'antd';
import Logo from '../../assets/logos/default-avatar.jpg';
import { MenuOutlined } from '@ant-design/icons';

const { Header } = Layout;
const LayoutHeader = ({ openDrawer, visible }) => {
    return (
        <Header className="site-layout__header">
            <div className="site-layout__header__logo">
                <div>
                    <a href="http://onelogin.tkgplatform.com.au/">
                        <img alt="Logo" src={Logo} width="75px" />
                    </a>
                </div>
            </div>
            <div className="site-layout__header__title">
                <span className="text-style-shadow h1">
                    UI Documents Composite
                </span>
            </div>
            <div style={{ flexGrow: 1 }}></div>
  
            <div className="site-layout__header__menu">
                <MenuOutlined
                    onClick={() => openDrawer(!visible)}
                />
            </div>
        </Header>
    );
};

export default LayoutHeader;

