import React, { useState } from 'react';
import { Form, Button, Divider, Drawer, Tooltip, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { AccommodationItineraryTKG } from "tkg-composite-ui";
import CardTKG from '../../../../components/Card';


const { Text } = Typography;

const AccommodationItinerary = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };
  
    const [form] = Form.useForm();

    return (
        <>
       
            <CardTKG title="Accommodation Itinerary"
                extra={
                    <Tooltip title="View Code">
                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                    </Tooltip>
                }
            >
                <Form form={form}>
                    <AccommodationItineraryTKG form={form} />
                </Form>
            </CardTKG>
            <Drawer
                title="View"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Accommodation Itinerary</b> <EditOutlined /></Divider>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import { AccommodationItineraryTKG } from "tkg-composite-ui";;

    <AccommodationItineraryTKG form={form} parentName={name} itemName='itemAccommodation'></AccommodationItineraryTKG>
    `}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default AccommodationItinerary;