import React, { useState } from 'react';
import { Button, Card, Collapse, Divider, Drawer, Form, Tooltip, Row, Col, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { InputTKG } from "tkg-composite-ui";

const { Panel } = Collapse;

const { Text } = Typography;

const SimpleInput = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const [form] = Form.useForm();

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="Input Components" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Input Default"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                                    </Tooltip>
                                }
                            >
                                <Form
                                    id="basic"
                                    name="basic"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    autoComplete="off"
                                    layout='vertical'
                                    form={form}
                                >
                                    <Form.Item name="textbox" label="Simple Input">
                                        <InputTKG name={'textbox'} form={form} placeholder="Please input" />
                                    </Form.Item>

                                    <Form.Item name="textbox1" label="Simple Input | Compulsory" rules={[{ required: true, message: 'Please input !' }]}>
                                        <InputTKG name={'textbox1'} form={form} placeholder="Please input" />
                                    </Form.Item>

                                    <Form.Item name="textbox2" label="Input Type Password">
                                        <InputTKG name={'textbox2'} form={form} isPasswordField={true} placeholder="Please input your password" />
                                    </Form.Item>


                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title="Simple Input"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Simple Input</b> <EditOutlined /></Divider>
                <Text code>size</Text> <Text>Sizes of an Input box ( default: large )</Text> <br />
                <Text code>name</Text> <Text>Name of an Input box</Text> <br />
                <Text code>maxLength</Text> <Text>Limited number of characters ( default: 50 )</Text><br />
                <Text code>isPasswordField</Text> <Text>If isPasswordField = true input type password else type text ( default: false )</Text><br />
                <Text code>form</Text> <Text>Call form method with <Text code>Form.useForm</Text> is a React Hooks that only works in functional component.</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import { InputTKG } from "tkg-composite-ui";

    <InputTKG />
    <Form
        id="basic"
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout='vertical'
        form={form}
    >
        <Form.Item name="textbox" label="Simple Input">
            <InputTKG name={'textbox'} form={form} />
        </Form.Item>
    </Form>
    `}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Simple Input | Compulsory</b> <EditOutlined /></Divider>
                <Text>To validate, the input needs to be put in the form</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    <Form
        id="basic"
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout='vertical'
        form={form}
    >
        <Form.Item name="textbox1" label="Simple Input | Compulsory" rules={[{ required: true, message: 'Please input !' }]}>
            <InputTKG name={'textbox1'} form={form} />
        </Form.Item>
    </Form>
`}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Input Password</b> <EditOutlined /></Divider>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    <Form
        id="basic"
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout='vertical'
        form={form}
    >
        <Form.Item name="textbox2" label="Input Type Password">
            <InputTKG name='textbox2' form={form} isPasswordField={true} />
        </Form.Item>
    </Form>
`}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default SimpleInput;
