import React from 'react';
import { Row, Col } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/en-au';
import { ButtonDeleteTKG, SwitchActiveTKG, TableTKG } from "tkg-composite-ui";
//import { isLoginSession, Constant } from "@tkgplatform/api";
import ModalAdd  from '../../components/productType/modalAdd';
import ModalPriceTableConfig from '../../components/productType/modalPriceTableConfig';
import PreviewForm from '../../components/productType/previewForm';

//import { useNavigate } from 'react-router-dom';
//import './user.scss';


const ProductType = (props) => {
  //const navigate = useNavigate();
  const [listData, setListData] = React.useState({ productTypes: [], data: {}});

  const Constant = { ButtonDeleteTKGMessage: {ProductType: 'Do you want to delete this product type?'}};
  const getProductTypes = async() => {
    //const result = await getListUser();
    //console.log('getUsers result?.data?.response', result?.data?.response);
    //setListData(result?.data?.response);
    const productTypes = [
      {id: 1, "name":"Tour","slug":"tour","type":"itinerary","schema":{"hasTransfersVia":true,"hasPickupTransfers":true,"hasAccommodation":true,
          "itineraryDetailFields":[{"type":"select","name":"Tour Guide","data":["Yes","No"]},{"type":"product","name":"Sightseeing/activity"}],
          "hasHighlight":true,"hasInclusions":true,"hasVideos":true,"hasTags":true,"hasPriceFrom":true,"hasUploadCarouselImages":true,"hasHomePageSection":true,"hasProductExclusiveTo":true,"hasTwoForOneDealsForCompany":true,"hasSpecialOfferFor":true,"hasIncludeTermsAndConditions":true,"hasIncludePrivacyPolicy":true,"hasSiteAppearanceLocation":true,"hasAgeRange":true,
          "overviewFields":[{"type":"text","name":"Duration"},{"type":"select","name":"Active Level","data":["Leisurely","Active","Challenging"]},{"type":"product","name":"Related Trip"},{"type":"text","name":"Operator"}]},
          priceTableConfigSchema:{"ignoreAutoBalance":true,"fields":[{"type":"text","name":"Tour Company","isSearchable":true},{"type":"text","name":"Country","isVisible":false,"isSearchable":true},{"type":"text","name":"Accommodation","isVisible":true},{"type":"text","name":"Group Size","isVisible":true},{"type":"text","name":"Tour Type","isVisible":true,"isSearchable":true}]}},
      {id: 2, "name":"Cruise","slug":"cruise","type":"itinerary","schema":{"hasAccommodation":true,"itineraryDetailFields":[{"type":"select","name":"Port Transfer","data":["Inclusion","Exclusion"]},{"type":"select","name":"Shore Excursion","data":["Inclusion","Exclusion"]}],"hasHighlight":true,"hasInclusions":true,"hasVideos":true,"overviewFields":[{"type":"text","name":"Duration"},{"type":"product","name":"Related Trip"}]},
      priceTableConfigSchema:{"fields":[{"type":"text","name":"Cruise Line","isVisible":true,"isSearchable":true},{"type":"text","name":"Country","isVisible":true},{"type":"text","name":"Ship Name","isVisible":true,"isSearchable":true},{"type":"text","name":"Ship Category","isVisible":true,"isSearchable":true},{"type":"text","name":"Cabin Type","isVisible":true,"isSearchable":true}]}},
      {id: 3, "name":"Accommodation","slug":"accommodation","type":"basic","schema":{"hasHighlight":true,"hasInclusions":true,"hasVideos":true,"overviewFields":[{"type":"text","name":"Accommodation Name"},{"type":"text","name":"Address"},{"type":"text","name":"Room Type"}]},
      priceTableConfigSchema:{}}
    ];
    const data = { 
      types: [
        { id: 'basic', name: 'Basic'},
        { id: 'itinerary', name: 'Itinerary Details'}
      ],
      defaultFields: [
        { name: 'Trip Code', description: 'Unique Generated Code for an Article'},
        { name: 'Title', description: 'The title of the Post'},
        { name: 'Slug', description: 'Automatically Generated'},
        { name: 'Product Summary', description: 'The summary for the Post'},
        { name: 'Itinerary Notes', description: 'Only included if the Itinerary product type is selected, is appended to itinerary days'},
        { name: 'Categories', description: 'The Categories for the Post'},
        { name: 'Brief Of Destination', description: 'A short description of the Destination'},
        { name: 'Upload Cover Image', description: 'The cover image for the Post'},
        { name: 'Product Public End Date', description: 'Product Public End Date'},
        { name: 'Publish On', description: 'When to automatically turn a draft into a published post'},
        { name: 'Status', description: 'Whether the post is a draft or published'},
        { name: 'Upload Map Image', description: 'Upload Map Image'},
      ],
      itineraryDetailsDefaultFields: [
        { name: 'Day', description: 'Day'},
        { name: 'Icon', description: 'Icon'},
        { name: 'Date', description: 'Date'},
        { name: 'Title', description: 'Title'},
        { name: 'Body', description: 'Body'},
        { name: 'Upload Image', description: 'Upload Image'},
      ],
      priceTableConfigDefaultFields: [
        { name: 'Trip Code', description: 'Unique Generated Code for an Article'},
        { name: 'Trip Name', description: 'The name of trip'},
        { name: 'Twin Share', description: 'Twin Share'},
        { name: 'Single Price', description: 'Single Price'},
        { name: 'Single Supplementary', description: 'Single Supplementary'},
        { name: 'Special', description: 'Special'},
        { name: 'Expire Date', description: 'Expire Date'},
        { name: 'Markup', description: 'Markup'},
        { name: 'Total Inventory', description: 'Total Inventory'},
        { name: 'Stocked Inventory', description: 'Stocked Inventory'},
        { name: 'Available Inventory', description: 'Available Inventory'},
        { name: 'Start Date', description: 'Start Date'},
        { name: 'End Date', description: 'End Date'},
        { name: 'Price is single/twin/triple/quad share', description: 'Price is single/twin/triple/quad share'},
      ],
      productFieldTypes: [
        { id: 'text', name: 'Plain Text'}, 
        { id: 'select', name: 'Dropdown'},
        { id: 'content', name: 'Linked Content Post (Related)'},
        { id: 'product', name: 'Linked Product Post (Related)'},
        //{ id: 'addon', name: 'Add Ons'},
        { id: 'date', name: 'Date'},
        { id: 'time', name: 'Time'},
        { id: 'datetime', name: 'Date/Time'},
        { id: 'daterange', name: 'Date Range'},
      ], 
      contentTypes:  [
        { id: 32, name:'Blog'},
        { id: 33, name:'All Types'},
        { id: 36, name:'Destination'},
        { id: 37, name:'Golf Course'},
        { id: 38, name:'Press Release'},
        { id: 39, name:'About Us'},
        { id: 40, name:'test tour'},
        { id: 42, name:'News'},
        { id: 43, name:'Terms Conditions'},
        { id: 44, name:'Investor Proposal'},
      ],
      priceTableConfigFieldType: [
        { id: 'text', name: 'Plain Text'}, 
        { id: 'select', name: 'Dropdown'},
        { id: 'content', name: 'Linked Content Post (Related)'},
        //{ id: 'product', name: 'Linked Product Post (Related)'},
        //{ id: 'addon', name: 'Add Ons'},
        { id: 'date', name: 'Date'},
        { id: 'time', name: 'Time'},
        { id: 'datetime', name: 'Date/Time'},
        { id: 'daterange', name: 'Date Range'},
        { id: 'csv', name: 'Comma Seperated Values'}
      ], 
      productTypes: productTypes
    };
    //listData.productTypes = productTypes;
    //listData.data = data;
    setListData({ productTypes: productTypes, data: data});
    //searchData(result?.data?.response.users, searchValue)
  }

  const deleteProductType = async(id) => {

  };

  React.useEffect(() => {
    // if (isLoginSession() !== true) {
    //   //return navigate(Constant.Pages.User);
    //   return navigate(Constant.Pages.Login);
    // }
    getProductTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    // { title: "#", key: "id", dataIndex: "id", align: "center", render: (text, record) => ( listSearchData.indexOf(record) + 1 ) },
    { title: "Name", key: "name", dataIndex: "name", align: "left" },
    { title: "Icon", key: "icon", dataIndex: "icon", align: "left" },
    { title: "Type", key: "type", dataIndex: "type", align: "left" },
    { title: "Action", key: "id", align: "center", render: (text, record) => ( 
      <Row gutter={[8,8]}>
        <Col span={6}><ModalPriceTableConfig item={record} data={listData?.data} callback={getProductTypes} parentName='priceTableConfigSchema' ></ModalPriceTableConfig></Col>
        <Col span={6}><ModalAdd item={record} data={listData?.data} callback={getProductTypes} ></ModalAdd></Col>
        <Col span={6}><PreviewForm selectedProductTypeItem={record} productTypes={listData?.productTypes} ></PreviewForm></Col>
        <Col span={6}><ButtonDeleteTKG id={record.id} apiMethod={deleteProductType} message={Constant.ButtonDeleteTKGMessage.ProductType} callback={getProductTypes}></ButtonDeleteTKG></Col>
      </Row>
     ) },
     { title: "Create Date", key: "createdAt", dataIndex: "createdAt", align: "left", render: (text, record) => ( dayjs(text).format('DD-MMM-YYYY HH:mm') ) },
    ];

  return (
    <div>
    <Row type="flex" >
      <Col className='container-add-product-type' span={24}>
        <ModalAdd item={{type: 'basic'}} data={listData?.data} callback={getProductTypes} ></ModalAdd>
      </Col>
    </Row>
    <Row type="flex" >
      <Col className='container-product-type' span={24}>
        <TableTKG columns={columns} dataSource={listData.productTypes} rowKey='id' isShowedFullData={true} />
      </Col>
    </Row>
    </div> 
)};
export default ProductType;
