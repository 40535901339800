import React from 'react'
import { Card, List } from 'antd';
import CreateQuote from './createQuote';
import ProductType from './productType';
import EditProductPost from './editProductPost';
import AddContactCRM from './addContactCRM';
import EditContactCRM from './editContactCRM';
import EditQuote from './editQuote';
import CreateBooking from './createBooking';
import EditBooking from './editBooking';



const data = [
    {
        title: 'Product Type',
        url: 'https://subscriber-dev.tkgplatform.com.au/producttype'
    },
    {
        title: 'Create Quote',
        url: 'https://subscriber-dev.tkgplatform.com.au/createquote'
    }
];

const index = () => {
    return (
        <>
            <Card title='Create/Edit manual Quote'>
                <CreateQuote></CreateQuote>
                <EditQuote />
            </Card>
            <br />
            <Card title='Create/Edit manual Booking'>
                <CreateBooking></CreateBooking>
                <EditBooking />
            </Card>
            <br />
            <Card title='Product Type'>
                <ProductType></ProductType>
            </Card>
            <br />
            <Card title='Edit Product Post'>
                <EditProductPost></EditProductPost>
            </Card>
            <Card title='Add/Edit Contact CRM'>
                <AddContactCRM></AddContactCRM>
                <EditContactCRM></EditContactCRM>
            </Card>
        </>
    )
}

export default index