import React from 'react';
import { Form, Button, Row, Col, Card } from 'antd';

import { AgentCommissionDetailsTKG, DiscountSectionTKG, PaymentDetailsTKG, OnlinePaymentLinkTKG, PaymentMethodTKG, TotalGrossAUDTKG, PricingDetailsGrossTKG, ActualReceivedPaymentStatusTKG } from "tkg-composite-ui";

const PassengerInvoice = (props) => {
  const data = {
    discountTypes: [
      { id: '1', name: 'Discount Percentage'},
      { id: '2', name: 'Discount Amount'},
    ]
  }
  const item = { tourRates: 15, commissionAmount: 1439.1, discountType: '1', discountPercent: 15, discountAmount: 500,
    totalGross: 9594, totalNet: 8154.9, totalDueDate: '',
    firstDepositGross: 1440, firstDepositNet: 1440, firstDepositDueDate: '2023-02-27', 
    balanceGross: 8154, balanceNet: 6714.9, balanceDueDate: '2023-02-28', 
    paymentMethods: [
      { date: '2022-12-28', typeName: 'NET 1st deposit', methodName: 'Credit On Hold Transfer'},
      { date: '2023-02-08', typeName: 'NET balance', methodName: 'Direct Credit'}
    ],
    totalPrice: 9594, bookingFee: 0, 
    pricingDetailsGrosses: [
      { title: 'Adult 1', fullName: 'Mrs. Caroline Grace Lee', price: 4797},
      { title: 'Adult 2', fullName: 'Mr. Paul Gerard Kean', price: 4797},
    ],
    actualPaymentFirstDepositReconciliation: true, agentPaymentStatusFirstDepositGross: 0, 
    agentPaymentStatusFirstDepositNet: 1440, agentPaymentStatusFirstDepositReceivedDate: '2022-12-28',
    actualPaymentBalanceReconciliation: true, agentPaymentStatusBalanceGross: 0, 
    agentPaymentStatusBalanceNet: 6714.90, agentPaymentStatusBalanceReceivedDate: '2023-02-27'
  };
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldValue('adults', [{firstName: 'Huy test', dateOfBirth: '2017-02-27'},{}]);
  }, [data]);

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  return (
    <Card title="Passenger Invoice">
    <Form form={form} onFinish={onFinish} layout="vertical" initialValues={item}>
      <Row gutter={[16,16]}>
          <Col span={12}>
            <AgentCommissionDetailsTKG form={form} ></AgentCommissionDetailsTKG>
            <DiscountSectionTKG form={form} data={data}></DiscountSectionTKG>
            <PaymentDetailsTKG form={form}></PaymentDetailsTKG>
            <Row gutter={[16,16]}>
              <Col span={12}><OnlinePaymentLinkTKG ></OnlinePaymentLinkTKG></Col>
              <Col span={12}><PaymentMethodTKG items={item.paymentMethods} ></PaymentMethodTKG></Col>
            </Row>
          </Col>   
          <Col span={12}>                                  
              <TotalGrossAUDTKG form={form} />
              <PricingDetailsGrossTKG form={form} itemName='pricingDetailsGrosses'/>
              <ActualReceivedPaymentStatusTKG form={form} />
          </Col> 
      </Row>   

      <Button type="primary" htmlType="submit">
        Submit
      </Button>
  </Form>
  </Card>
)};

export default PassengerInvoice;
