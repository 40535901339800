import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Started from './pages/Started/Started'
import SimpleComposite from './pages/SimpleComposite'
import PackageComposite from './pages/PackageComposite'
import FormComposite from './pages/FormComposite'


const root = ReactDOM.createRoot(
    document.getElementById('root'),
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} >
                    <Route path='started' element={<Started />} />
                    <Route path='simple' element={<SimpleComposite />} />
                    <Route path='package' element={<PackageComposite />} />
                    <Route path='form-composite' element={<FormComposite />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
