import React, { useState } from 'react';
import { Button, Divider, Drawer, Tooltip, Typography, Form } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { PassengerDetailsTKG } from 'tkg-composite-ui';
import 'dayjs/locale/en-au';
import CardTKG from '../../../components/Card';

const { Text } = Typography;

const PassengerDetails = (props) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };

    const data = {
        titles: [
            //{ label: 'Title', value: '' },
            { label: 'Mr.', value: 'Mr.' },
            { label: 'Mrs.', value: 'Mrs.' },
            { label: 'Ms.', value: 'Ms.' },
            { label: 'Mstr.', value: 'Mstr.' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Sir', value: 'Sir' },
            { label: 'Lady', value: 'Lady' },
        ],
        countries: [ 'Australia', 'Vietnam' ],
        phoneCountryCodes: [
            { id: '84', name: '(+84) Vietnam' },
            { id: '61', name: '(+61) Australia' },
        ]
    };
    const items = [{
        orderCodeLink: 'https://agentportal.tweetworldtravel.com/admin/quotes/TWT865134',
        isLeader: false, dateOfBirth: '2017-02-27', bedding: 'test bedding',
        title: 'Mr.', countryOfPassport: 'Vietnam', medical: 'test', firstName: 'Minh Long', surName: 'Tran',
        email: 'pika2017@gmail.com', phoneCountryCode: '84', phoneNumber: '0986977632',
        emergencyPhoneCountryCode: '84', emergencyPhoneNumber: '0913761279', emergencyContactName: 'Quoc Huy Tran'
    },
    {
        orderCodeLink: '',
        isLeader: true, dateOfBirth: '1984-12-08', bedding: 'test',
        title: 'Mr.', countryOfPassport: 'Australia', medical: 'test medical', firstName: 'Quoc Huy', surName: 'Tran',
        email: 'quochuy112812@gmail.com', phoneCountryCode: '61', phoneNumber: '0468679345',
        emergencyPhoneCountryCode: '61', emergencyPhoneNumber: '0468666345', emergencyContactName: 'Quoc Huy Tran'
    }
    ];
    const [form] = Form.useForm();

    React.useEffect(() => {
        form.setFieldValue('adults', [{ firstName: 'Huy test', dateOfBirth: '2017-02-27' }, {}]);
    }, [data]);

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    return (
        <>
            <CardTKG title="Passenger Details"
                extra={
                    <Tooltip title="View Code">
                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                    </Tooltip>
                }
            >
                <Form form={form} onFinish={onFinish} layout="vertical" >
                    <PassengerDetailsTKG form={form} itemName="passengerDetails" items={items} data={data} ></PassengerDetailsTKG>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </CardTKG>
            <Drawer
                title="View"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Adult Composite</b> <EditOutlined /></Divider>
                <Text code>Composite need use with useEffect</Text> <br />
                <Text code>setFieldValue (field name inside AdultTKG)</Text> <Text>title: ' ', firstName: ' ', surName: ' ', dateOfBirt: ' ', countryOfPassport: ' '</Text> <br />
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
                    Code Here
                    `}
                </SyntaxHighlighter>
            </Drawer>

        </>

    )
};

export default PassengerDetails;

