import React, { useState } from 'react';
import { Button, Card, Collapse, Divider, Drawer, Tooltip, Row, Col, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { TextEditorTKG } from 'tkg-composite-ui';

const { Panel } = Collapse;

const { Text } = Typography;

const TextEditorApp = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="Text Editor Components" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Editor Default"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                                    </Tooltip>
                                }
                            >
                                <TextEditorTKG />
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title="Editor"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Simple Editor</b> <EditOutlined /></Divider>
                <Text code>placeholder</Text> <Text>The default value for the empty editor</Text> <br />
                <Text code>value</Text> <Text>Value for the editor as a controlled component</Text> <br />
                <Text code>onChange(content, delta, source, editor)</Text> <Text>Called back with the new contents of the editor after change</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import {TextEditorTKG}  from 'tkg-composite-ui';

    <Form.Item name="TextEditor" label="Simple Text Editor:" >
        <TextEditorTKG form={form} />
    </Form.Item>
    `}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default TextEditorApp;
