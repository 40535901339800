import React, {useState} from 'react';
import { Form, Button, Space } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { StepsTKG, ProductPostOverviewTKG, QuoteItineraryDetailsTKG, OtherInformationTKG, TermsAndConditionsTKG, ModalFormTKG } from "tkg-composite-ui";
import dayjs from 'dayjs';
import 'dayjs/locale/en-au';

const EditProductPost = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };    
  const hideModal = () => {
    setIsModalOpen(false);
  };
  
  const steps = [
    {
        title: 'Overview',
        description: 'Description for Overview'
    },
    {
        title: 'Tour Itinerary Details',
        description: 'Description for Tour Itinerary Details',
    },
    {
        title: 'Other information',
        description: 'Description for Other information',
    },
    {
      title: 'Terms & Conditions',
      description: 'Description for Terms & Conditions',
    },
  ];
  const [current, setCurrent] = useState(0);
  const [itemsSteps, setItemsSteps] = useState(steps);

  const [productTypeItem, setProductTypeItem] = React.useState({});
  const [data, setData] = React.useState({});
  
  const [formProductPostOverviewTKG] = Form.useForm();
  const [itemProductPostOverviewTKG, setItemProductPostOverviewTKG] = React.useState({});


  const [formQuoteItineraryDetailsTKG] = Form.useForm();
  const [itemsQuoteItineraryDetails, setItemsQuoteItineraryDetails] = React.useState([]);

  const [formOtherInformationTKG] = Form.useForm();
  const [itemsOtherInformationTKG, setItemsOtherInformationTKG] = React.useState([{}]);

  const [formTermsAndConditionsTKG] = Form.useForm();
  const [itemTermsAndConditionsTKG, setItemTermsAndConditionsTKG] = React.useState({});

  React.useEffect(() => {
    const productTypes = [
      {id: 1, "name":"Tour","slug":"tour","type":"itinerary","schema":{"hasTransfersVia":true,"hasPickupTransfers":true,"hasAccommodation":true,
          "itineraryDetailFields":[{"type":"select","name":"Tour Guide","data":["Yes","No"]},{"type":"product","name":"Sightseeing/activity"}],
          "hasHighlight":true,"hasInclusions":true,"hasVideos":true,"hasTags":true,"hasPriceFrom":true,"hasUploadCarouselImages":true,"hasHomePageSection":true,"hasProductExclusiveTo":true,"hasTwoForOneDealsForCompany":true,"hasSpecialOfferFor":true,"hasIncludeTermsAndConditions":true,"hasIncludePrivacyPolicy":true,"hasSiteAppearanceLocation":true,"hasAgeRange":true,
          "overviewFields":[{"type":"text","name":"Duration"},{"type":"select","name":"Active Level","data":["Leisurely","Active","Challenging"]},{"type":"product","name":"Related Trip"},{"type":"text","name":"Operator"}]},
          priceTableConfigSchema:{"ignoreAutoBalance":true,"fields":[{"type":"text","name":"Tour Company","isSearchable":true},{"type":"text","name":"Country","isVisible":false,"isSearchable":true},{"type":"text","name":"Accommodation","isVisible":true},{"type":"text","name":"Group Size","isVisible":true},{"type":"text","name":"Tour Type","isVisible":true,"isSearchable":true}]}},
      {id: 2, "name":"Cruise","slug":"cruise","type":"itinerary","schema":{"hasAccommodation":true,"itineraryDetailFields":[{"type":"select","name":"Port Transfer","data":["Inclusion","Exclusion"]},{"type":"select","name":"Shore Excursion","data":["Inclusion","Exclusion"]}],"hasHighlight":true,"hasInclusions":true,"hasVideos":true,"overviewFields":[{"type":"text","name":"Duration"},{"type":"product","name":"Related Trip"}]},
      priceTableConfigSchema:{"fields":[{"type":"text","name":"Cruise Line","isVisible":true,"isSearchable":true},{"type":"text","name":"Country","isVisible":true},{"type":"text","name":"Ship Name","isVisible":true,"isSearchable":true},{"type":"text","name":"Ship Category","isVisible":true,"isSearchable":true},{"type":"text","name":"Cabin Type","isVisible":true,"isSearchable":true}]}},
      {id: 3, "name":"Accommodation","slug":"accommodation","type":"basic","schema":{"hasHighlight":true,"hasInclusions":true,"hasVideos":true,"overviewFields":[{"type":"text","name":"Accommodation Name"},{"type":"text","name":"Address"},{"type":"text","name":"Room Type"}]},
      priceTableConfigSchema:{}}
    ];

    const selectedProductTypeItem = productTypes[1];
    const overview = { type: selectedProductTypeItem.slug, numberOfRooms: 0, title:'Huy Test', slug:'huy_test', inclusions: [{}], status: '0'};
    setProductTypeItem(selectedProductTypeItem);
    setItemProductPostOverviewTKG(overview);
    
    setData({productTypes: productTypes, 
      inclusions: [
        {name: 'First-Class Service'},
        {name: 'Sightseeing'},
        {name: 'Accommodation'}
      ],
      titles: [
        //{ label: 'Title', value: '' },
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Mrs.', value: 'Mrs.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mstr.', value: 'Mstr.' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Prof.', value: 'Prof.' },
        { label: 'Sir', value: 'Sir' },
        { label: 'Lady', value: 'Lady' },
      ],
      countries: [ 'Australia', 'Vietnam' ],
      companies: [{"id":835,"text":"Leisurecom Group"},{"id":878,"text":"Innovative Travel Company"},{"id":885,"text":"Complete Business Travel"},{"id":918,"text":"Crooked Compass"},{"id":919,"text":"The Events Company"},{"id":928,"text":"Vinpearl Joint Stock Company \u2013 Vingroup JSC"},{"id":951,"text":"COMO Hotels"},{"id":1099,"text":"TONKIN CRUISES COMPANY LIMITED"},{"id":1790,"text":"Travco Travel Company of Egypt"},{"id":1817,"text":"hitcompany"},{"id":1822,"text":"CF Mekong (Compagnie Fluviale du Mekong)"},{"id":1836,"text":"Leisurecom"},{"id":1882,"text":"INCOMFRANCE"},{"id":1908,"text":"COMO Point Yamu Phuket"},{"id":2019,"text":"The Travel & Cruise Company"},{"id":2076,"text":"Compass Holidays"}],
      statuses: [
        { label: 'Draft', value: '0' },
        { label: 'Publish', value: '1' },
        { label: 'Expired', value: '2' },
      ],
      transferVias: [
        {label: 'Select Transfer Via', value: 'none' },
        {label: 'On Your Own Arrangement', value: 'OnYourOwnArrangement' }, 
        {label: 'Domestic Flight', value: 'Domestic' }, 
        {label: 'International Flight', value: 'International' }, 
        {label: 'Train', value: 'Train' }, 
        {label: 'Ferry', value: 'Ferry' }, 
        {label: 'Boat', value: 'Boat' }, 
        {label: 'Helicopter', value: 'Helicopter' }, 
        {label: 'Seaplane', value: 'Seaplane' }, 
        {label: 'PrivateJet', value: 'PrivateJet' }],
      pickupTransfers: [
        {label: 'None', value: 'none' }, 
        {label: 'Private Car', value: 'Private Car' }, 
        {label: 'Taxi', value: 'Taxi' }, 
        {label: 'Seat In Coach', value: 'Coach' }, 
        {label: 'Ferry', value: 'Ferry' }, 
        {label: 'Boat', value: 'Boat' }, 
        {label: 'Seaplane', value: 'Seaplane' }],
      tripOptionses: [
        {label: 'None', value: 'none' }, 
        {label: 'One-way', value: 'OneWay' }, 
        {label: 'Return', value: 'Return' }],
      products: [
        {id: 'TRMC-9395', name: 'TRMC-9395 - Highlights of Sydney, Adelaide,  Kangaroo Island and the Murray River- rail and cruise package'},
        {id: 'TREB-8785', name: 'TREB-8785 - Unforgettable Darwin, Adelaide and River Murray rail and cruise package'},
        {id: 'TRJT-1365', name: 'TRJT-1365 - Journey of Discovery from Melbourne to Adelaide rail and cruise package'},
        {id: 'RLAH-4054', name: 'RLAH-4054 - Best of Australia\'s Southeast Rail and Cruise Package'},
        {id: 'OCT01ID', name: 'OCT01ID - Luxury Small Group Tour India Golden Triangle With Bharatpur'},
        {id: '17DGLFCRS', name: '17DGLFCRS - ULTIMATE LUXURY FULLY ESCORTED GOLF CRUISE TOUR WITH HELI GOLF (FROM SIEM REAP TO HANOI)'},
        {id: 'PCKHL-3726', name: 'PCKHL-3726 - Wonders of the Great Barrier Reef, Brisbane and South Australia- Rail & Cruise Package'},
        {id: 'LD2021T', name: 'LD2021T - 12 DAY VIETNAM AND CAMBODIA MEKONG RIVER CRUISE | 2-For-1 Deal'},
        {id: 'TW49241T', name: 'TW49241T - 9-DAY LUXURY VIETNAM AND CAMBODIA MEKONG RIVER CRUISE  with RV Indochine II'},
        {id: 'TC251KB', name: 'TC251KB - 15 DAY BEST OF VIETNAM CAMBODIA MEKONG RIVER CRUISE AND HALONG BAY'},
        {id: 'VNC17_P', name: 'VNC17_P - 18-Day Luxury Vietnam & The Mekong River With RV Lan Diep Cruise'},
      ],
      siteAppearanceLocations: [
        {id:'none', name: 'Select Website'},
        {id:'TweetTours', name: 'Tweet Tours'},
        {id:'TweetWorldCruises', name: 'Tweet World Cruises'},
        {id:'TweetVacation', name: 'Tweet Vacation'},
        {id:'WorldwideRiverCruises', name: 'Worldwide River Cruises'},
        {id:'CroisiCruises', name: 'CroisiEurope Cruises'},
        {id:'AsiaOnlineTours', name: 'Asia Online Tours'},
        {id:'AsiaSchoolTours', name: 'Asia School Tours'}
      ],
      homePageSections: [
        {id: 'none', name: 'Select a home page location'},
        {id: 'Carousel', name: 'Package Carousel'},
        {id: 'SpecialOffers', name: 'Special Offers'},
        {id: 'MostPopularPackages', name: 'Most Popular Packages'},
        {id: 'MostPopularHotelDestinations', name: 'Most Popular Hotel Destinations'}
      ],
      productCategories: [
        {id: 2, pid: 0, level: 1, name: 'Tour'},
{id: 19, pid: 0, level: 1, name: 'Cruise'},
{id: 118, pid: 0, level: 1, name: 'ShoreExcursion'},
{id: 124, pid: 0, level: 1, name: 'Holiday Package'},
{id: 126, pid: 0, level: 1, name: 'ExclusivePackage'},
{id: 136, pid: 126, level: 2, name: 'Exclusive Deals'},
{id: 144, pid: 136, level: 3, name: 'Laos'},
{id: 147, pid: 136, level: 3, name: 'Sri Lanka'},
{id: 149, pid: 136, level: 3, name: 'South Korea'},
{id: 243, pid: 126, level: 2, name: 'Tour Destinations'},
{id: 244, pid: 243, level: 3, name: 'Laos'},
{id: 245, pid: 243, level: 3, name: 'Cambodia'},
{id: 246, pid: 126, level: 2, name: 'Group Tours'},
{id: 247, pid: 246, level: 3, name: 'Multi-country Tours'},
{id: 248, pid: 126, level: 2, name: 'Mekong River Cruise'},
{id: 249, pid: 243, level: 3, name: 'Sri Lanka'},
{id: 250, pid: 243, level: 3, name: 'Vietnam'},
{id: 251, pid: 243, level: 3, name: 'Japan'},
{id: 253, pid: 243, level: 3, name: 'South Korea'},
{id: 274, pid: 126, level: 2, name: 'Small Group Tours'},
{id: 275, pid: 126, level: 2, name: 'Asia Tours'},
{id: 284, pid: 275, level: 3, name: 'Exclusive Deals'},
{id: 285, pid: 284, level: 4, name: 'Destinations'},
{id: 286, pid: 285, level: 5, name: 'Laos'},
{id: 287, pid: 285, level: 5, name: 'Cambodia'},
{id: 288, pid: 284, level: 4, name: 'Tour Type'},
{id: 289, pid: 284, level: 4, name: 'Group Tours'},
{id: 290, pid: 289, level: 5, name: 'Multi-country Tours'},
{id: 291, pid: 288, level: 5, name: 'Group Tours'},
{id: 362, pid: 19, level: 2, name: 'Worldwide Cruises'},
{id: 363, pid: 377, level: 3, name: 'Northern Europe'},
{id: 364, pid: 377, level: 3, name: 'Southern Europe'},
{id: 365, pid: 377, level: 3, name: 'Central Europe'},
{id: 366, pid: 377, level: 3, name: 'French Rivers And Canals'},
{id: 367, pid: 377, level: 3, name: 'Multi River Cruises'},
{id: 368, pid: 19, level: 2, name: 'Themed Cruises'},
{id: 369, pid: 362, level: 3, name: 'Mekong River'},
{id: 370, pid: 580, level: 4, name: 'Myanmar River Cruises'},
{id: 371, pid: 580, level: 4, name: 'Southern Africa'},
{id: 372, pid: 580, level: 4, name: 'North America (Canada)'},
{id: 373, pid: 580, level: 4, name: 'Russia'},
{id: 374, pid: 362, level: 3, name: 'Coastal Cruises'},
{id: 375, pid: 580, level: 4, name: 'India'},
{id: 376, pid: 580, level: 4, name: 'Egypt'},
{id: 377, pid: 19, level: 2, name: 'Europe Cruises'},
{id: 378, pid: 368, level: 3, name: 'Family Club'},
{id: 379, pid: 368, level: 3, name: 'Hiking Cruises'},
{id: 380, pid: 368, level: 3, name: 'Biking Cruises'},
{id: 382, pid: 368, level: 3, name: 'Christmas And New Year'},
{id: 383, pid: 368, level: 3, name: 'Gastronomy And Wine Cruises'},
{id: 384, pid: 363, level: 4, name: 'The Romantic Rhine'},
{id: 385, pid: 363, level: 4, name: 'The Dutch And Belgian Canals'},
{id: 386, pid: 363, level: 4, name: 'The Rhine and its affluents'},
{id: 387, pid: 363, level: 4, name: 'Baltic Berlin And Copenhagen'},
{id: 388, pid: 363, level: 4, name: 'Germany'},
{id: 389, pid: 363, level: 4, name: 'Belgium'},
{id: 390, pid: 363, level: 4, name: 'Denmark'},
{id: 391, pid: 363, level: 4, name: 'France'},
{id: 392, pid: 363, level: 4, name: 'Luxembourg'},
{id: 393, pid: 363, level: 4, name: 'The Netherlands'},
{id: 394, pid: 363, level: 4, name: 'Poland'},
{id: 395, pid: 363, level: 4, name: 'Czech Republic'},
{id: 396, pid: 363, level: 4, name: 'Switzerland'},
{id: 397, pid: 363, level: 4, name: 'The Elbe'},
{id: 398, pid: 364, level: 4, name: 'Venice and its lagoon'},
{id: 399, pid: 364, level: 4, name: 'The Douro'},
{id: 400, pid: 364, level: 4, name: 'The Guadalquivir and Guadiana'},
{id: 401, pid: 364, level: 4, name: 'Spain'},
{id: 402, pid: 364, level: 4, name: 'Italy'},
{id: 403, pid: 364, level: 4, name: 'Portugal'},
{id: 404, pid: 365, level: 4, name: 'The beautiful blue Danube'},
{id: 405, pid: 365, level: 4, name: 'The Danube and the Black Sea'},
{id: 406, pid: 365, level: 4, name: 'The Sava and the Tisza'},
{id: 407, pid: 365, level: 4, name: 'Germany'},
{id: 408, pid: 365, level: 4, name: 'Austria'},
{id: 409, pid: 365, level: 4, name: 'Bulgaria'},
{id: 410, pid: 365, level: 4, name: 'Croatia'},
{id: 411, pid: 365, level: 4, name: 'Hungary'},
{id: 412, pid: 365, level: 4, name: 'Romania'},
{id: 413, pid: 365, level: 4, name: 'Serbia'},
{id: 414, pid: 365, level: 4, name: 'Slovakia'},
{id: 415, pid: 366, level: 4, name: 'The Seine'},
{id: 416, pid: 366, level: 4, name: 'The Loire'},
{id: 417, pid: 366, level: 4, name: 'The Rhone and Saone'},
{id: 418, pid: 366, level: 4, name: 'The Garonne, Gironde and Dordogne'},
{id: 419, pid: 369, level: 4, name: 'Vietnam &amp; Cambodia'},
{id: 420, pid: 369, level: 4, name: 'Laos &amp; Thailand'},
{id: 421, pid: 369, level: 4, name: 'Laos Cruises'},
{id: 422, pid: 369, level: 4, name: 'Laos and Cambodia Cruises'},
{id: 424, pid: 368, level: 3, name: 'Golf Cruises'},
{id: 457, pid: 2, level: 2, name: 'Tour Types'},
{id: 458, pid: 457, level: 3, name: 'Type of Tours'},
{id: 459, pid: 2, level: 2, name: 'Tour Destinations'},
{id: 460, pid: 570, level: 4, name: 'Thailand'},
{id: 461, pid: 458, level: 4, name: 'Luxury Tours'},
{id: 462, pid: 457, level: 3, name: 'Luxury &amp; Lifestyle Holidays'},
{id: 463, pid: 462, level: 4, name: 'Luxury Holidays'},
{id: 468, pid: 570, level: 4, name: 'South Korea'},
{id: 469, pid: 458, level: 4, name: 'Discovery Tours'},
{id: 482, pid: 570, level: 4, name: 'India'},
{id: 483, pid: 462, level: 4, name: 'Wellness Tours'},
{id: 484, pid: 570, level: 4, name: 'China'},
{id: 485, pid: 570, level: 4, name: 'Japan'},
{id: 487, pid: 570, level: 4, name: 'Vietnam'},
{id: 489, pid: 570, level: 4, name: 'Multi-Country'},
{id: 492, pid: 457, level: 3, name: 'Group Tours'},
{id: 496, pid: 462, level: 4, name: 'Spa &amp; Wellness'},
{id: 497, pid: 570, level: 4, name: 'Sri Lanka'},
{id: 498, pid: 462, level: 4, name: 'Yoga Tours'},
{id: 501, pid: 462, level: 4, name: 'Romance &amp; Honeymoon'},
{id: 503, pid: 570, level: 4, name: 'Myanmar'},
{id: 504, pid: 570, level: 4, name: 'Nepal'},
{id: 507, pid: 492, level: 4, name: 'Wildlife &amp; Safari Tours'},
{id: 508, pid: 570, level: 4, name: 'Bhutan'},
{id: 509, pid: 458, level: 4, name: 'Classic Tours'},
{id: 512, pid: 570, level: 4, name: 'Mongolia'},
{id: 513, pid: 458, level: 4, name: 'Adventure Tours'},
{id: 514, pid: 492, level: 4, name: 'Motorbike &amp; Bicycle Tours'},
{id: 521, pid: 458, level: 4, name: 'Private Tours'},
{id: 525, pid: 570, level: 4, name: 'Mekong Delta'},
{id: 526, pid: 492, level: 4, name: 'Multi-Country Tours'},
{id: 527, pid: 492, level: 4, name: 'Small Group Tours'},
{id: 528, pid: 458, level: 4, name: 'Family Tours'},
{id: 533, pid: 457, level: 3, name: 'Asia River Cruises'},
{id: 534, pid: 533, level: 4, name: 'Vietnam &amp; Cambodia'},
{id: 535, pid: 533, level: 4, name: 'Laos &amp; Thailand'},
{id: 536, pid: 533, level: 4, name: 'Laos Cruises'},
{id: 537, pid: 533, level: 4, name: 'Laos &amp; Cambodia'},
{id: 538, pid: 458, level: 4, name: 'Independent Tours'},
{id: 540, pid: 492, level: 4, name: 'Escorted Tours'},
{id: 541, pid: 492, level: 4, name: 'Battlefield Tours'},
{id: 543, pid: 570, level: 4, name: 'Borneo &amp; Malaysia'},
{id: 544, pid: 570, level: 4, name: 'Laos'},
{id: 545, pid: 570, level: 4, name: 'Tibet'},
{id: 570, pid: 459, level: 3, name: 'Asia Tours'},
{id: 571, pid: 459, level: 3, name: 'Europe Tours'},
{id: 572, pid: 571, level: 4, name: 'Portugal'},
{id: 573, pid: 570, level: 4, name: 'Cambodia'},
{id: 575, pid: 459, level: 3, name: 'Oceania Tours'},
{id: 576, pid: 575, level: 4, name: 'Australia'},
{id: 579, pid: 580, level: 4, name: 'China '},
{id: 580, pid: 362, level: 3, name: 'Destination'},
{id: 583, pid: 374, level: 4, name: 'Vietnam'},
{id: 585, pid: 19, level: 2, name: 'Cruise Type'},
{id: 629, pid: 0, level: 1, name: 'Visa'},
{id: 630, pid: 629, level: 2, name: 'Visa'},
{id: 645, pid: 0, level: 1, name: 'Rail Package'},
{id: 646, pid: 645, level: 2, name: 'Rail'},
{id: 655, pid: 853, level: 3, name: 'Asia'},
{id: 656, pid: 655, level: 4, name: 'Vietnam'},
{id: 657, pid: 655, level: 4, name: 'Cambodia'},
{id: 658, pid: 0, level: 1, name: '2-for-1 Deal'},
{id: 659, pid: 658, level: 2, name: '2-for-1 deal'},
{id: 660, pid: 0, level: 1, name: 'Accommodation'},
{id: 661, pid: 660, level: 2, name: 'Accommodation'},
{id: 664, pid: 0, level: 1, name: 'Golf'},
{id: 668, pid: 0, level: 1, name: 'Sightseeing/Activity'},
{id: 669, pid: 580, level: 4, name: 'Australia'},
{id: 674, pid: 0, level: 1, name: 'Transfer'},
{id: 675, pid: 674, level: 2, name: 'Transfer'},
{id: 690, pid: 853, level: 3, name: 'Oceania'},
{id: 691, pid: 690, level: 4, name: 'Australia'},
{id: 717, pid: 0, level: 1, name: 'Insurance'},
{id: 718, pid: 717, level: 2, name: 'Insurance'},
{id: 719, pid: 0, level: 1, name: 'Flight'},
{id: 720, pid: 719, level: 2, name: 'Flight'},
{id: 721, pid: 0, level: 1, name: 'Other Service'},
{id: 722, pid: 721, level: 2, name: 'Other Service'},
{id: 753, pid: 492, level: 4, name: 'School Tours'},
{id: 768, pid: 852, level: 3, name: 'Rail &amp; Cruise'},
{id: 770, pid: 852, level: 3, name: 'Wellness &amp; Nature package'},
{id: 772, pid: 668, level: 2, name: 'Sightseeing Types'},
{id: 773, pid: 668, level: 2, name: 'Sightseeing Destinations'},
{id: 774, pid: 772, level: 3, name: 'Sightseeing'},
{id: 775, pid: 772, level: 3, name: 'Cruise Package'},
{id: 776, pid: 772, level: 3, name: 'Cycling Package'},
{id: 777, pid: 772, level: 3, name: 'Golf  Package'},
{id: 778, pid: 772, level: 3, name: 'Shore Excursion'},
{id: 779, pid: 772, level: 3, name: 'Short Package'},
{id: 780, pid: 772, level: 3, name: 'Wellness Package'},
{id: 781, pid: 773, level: 3, name: 'Asia'},
{id: 782, pid: 773, level: 3, name: 'Europe'},
{id: 783, pid: 773, level: 3, name: 'Oceania'},
{id: 784, pid: 781, level: 4, name: 'Thailand'},
{id: 785, pid: 781, level: 4, name: 'South Korea'},
{id: 786, pid: 781, level: 4, name: 'India'},
{id: 787, pid: 781, level: 4, name: 'China'},
{id: 788, pid: 781, level: 4, name: 'Japan'},
{id: 789, pid: 781, level: 4, name: 'Vietnam'},
{id: 790, pid: 781, level: 4, name: 'Multi-Country'},
{id: 791, pid: 781, level: 4, name: 'Sri Lanka'},
{id: 792, pid: 781, level: 4, name: 'Myanmar'},
{id: 793, pid: 781, level: 4, name: 'Nepal'},
{id: 794, pid: 781, level: 4, name: 'Bhutan'},
{id: 795, pid: 781, level: 4, name: 'Mongolia'},
{id: 796, pid: 781, level: 4, name: 'Mekong Delta'},
{id: 797, pid: 781, level: 4, name: 'Borneo &amp; Malaysia'},
{id: 798, pid: 781, level: 4, name: 'Laos'},
{id: 799, pid: 781, level: 4, name: 'Tibet'},
{id: 800, pid: 781, level: 4, name: 'Cambodia'},
{id: 801, pid: 19, level: 2, name: 'Sea Cruises'},
{id: 802, pid: 801, level: 3, name: 'Mediterranean Cruises'},
{id: 803, pid: 801, level: 3, name: 'Atlantic Cruises'},
{id: 804, pid: 801, level: 3, name: 'Adriatic Sea'},
{id: 805, pid: 801, level: 3, name: 'Red Sea'},
{id: 806, pid: 802, level: 4, name: 'Corsican Cruises'},
{id: 807, pid: 802, level: 4, name: 'Cyprus and the Holy Land'},
{id: 808, pid: 802, level: 4, name: 'The Greek Islands and Croatia'},
{id: 809, pid: 802, level: 4, name: 'The Greek Islands and Cyprus'},
{id: 810, pid: 803, level: 4, name: 'The Canary Islands'},
{id: 811, pid: 803, level: 4, name: 'The Andalusian flatlands and Morocco'},
{id: 812, pid: 804, level: 4, name: 'Croatia and Montenegro'},
{id: 813, pid: 804, level: 4, name: 'Croatia, Greece, Albany and Montenegro'},
{id: 814, pid: 805, level: 4, name: 'Egypt and Jordan'},
{id: 817, pid: 570, level: 4, name: 'Maldives'},
{id: 820, pid: 664, level: 2, name: 'Golf Holiday Types'},
{id: 821, pid: 820, level: 3, name: 'Golf Small Group Tours'},
{id: 822, pid: 820, level: 3, name: 'Golf Cruises'},
{id: 823, pid: 820, level: 3, name: 'Golf Packages'},
{id: 824, pid: 820, level: 3, name: 'Golf Tournaments'},
{id: 825, pid: 664, level: 2, name: 'Destinations'},
{id: 826, pid: 825, level: 3, name: 'Asia'},
{id: 827, pid: 826, level: 4, name: 'Vietnam'},
{id: 828, pid: 826, level: 4, name: 'Laos'},
{id: 829, pid: 826, level: 4, name: 'Thailand'},
{id: 830, pid: 825, level: 3, name: 'Europe'},
{id: 831, pid: 825, level: 3, name: 'Oceania'},
{id: 832, pid: 831, level: 4, name: 'Australia'},
{id: 833, pid: 831, level: 4, name: 'New Zealand'},
{id: 834, pid: 830, level: 4, name: 'Scotland'},
{id: 835, pid: 826, level: 4, name: 'Cambodia'},
{id: 836, pid: 830, level: 4, name: 'United Kingdom (UK)'},
{id: 839, pid: 826, level: 4, name: 'China'},
{id: 840, pid: 826, level: 4, name: 'Sri Lanka'},
{id: 841, pid: 822, level: 4, name: 'Mekong River'},
{id: 842, pid: 823, level: 4, name: 'Stay and Play'},
{id: 843, pid: 823, level: 4, name: 'Golf Vacation'},
{id: 844, pid: 821, level: 4, name: 'Luxury Golf Tours'},
{id: 846, pid: 645, level: 2, name: 'Rail Types'},
{id: 847, pid: 846, level: 3, name: 'Luxury Rail'},
{id: 848, pid: 645, level: 2, name: 'Rail Destinations'},
{id: 849, pid: 848, level: 3, name: 'Asia'},
{id: 850, pid: 849, level: 4, name: 'India'},
{id: 851, pid: 826, level: 4, name: 'Japan'},
{id: 852, pid: 124, level: 2, name: 'Package Types'},
{id: 853, pid: 124, level: 2, name: 'Destinations'},
{id: 854, pid: 853, level: 3, name: 'Europe'},
{id: 855, pid: 655, level: 4, name: 'Thailand'},
{id: 856, pid: 655, level: 4, name: 'South Korea'},
{id: 857, pid: 655, level: 4, name: 'Maldives'},
{id: 859, pid: 655, level: 4, name: 'Tibet'},
{id: 861, pid: 655, level: 4, name: 'India'},
{id: 862, pid: 655, level: 4, name: 'China'},
{id: 863, pid: 655, level: 4, name: 'Japan'},
{id: 864, pid: 655, level: 4, name: 'Sri Lanka'},
{id: 865, pid: 655, level: 4, name: 'Myanmar'},
{id: 866, pid: 655, level: 4, name: 'Nepal'},
{id: 867, pid: 655, level: 4, name: 'Bhutan'},
{id: 868, pid: 655, level: 4, name: 'Mongolia'},
{id: 869, pid: 655, level: 4, name: 'Laos'},
{id: 870, pid: 655, level: 4, name: 'Borneo &amp; Malaysia'},
{id: 871, pid: 655, level: 4, name: 'Mekong Delta'},
{id: 872, pid: 655, level: 4, name: 'Multi-Country'},
{id: 873, pid: 854, level: 4, name: 'Portugal'},
{id: 874, pid: 854, level: 4, name: 'United Kingdom'},
{id: 875, pid: 854, level: 4, name: 'Scotland'},
{id: 877, pid: 690, level: 4, name: 'New Zealand'},
{id: 881, pid: 848, level: 3, name: 'Oceania'},
{id: 882, pid: 881, level: 4, name: 'Australia'},
{id: 883, pid: 0, level: 1, name: 'Liveaboard'},
{id: 885, pid: 883, level: 2, name: 'Destination'},
{id: 888, pid: 885, level: 3, name: 'Maldives'},
{id: 889, pid: 848, level: 3, name: 'Europe'},
{id: 890, pid: 889, level: 4, name: 'Spain'},
{id: 891, pid: 848, level: 3, name: 'South Africa'},
{id: 892, pid: 889, level: 4, name: 'Portugal'},
{id: 912, pid: 852, level: 3, name: 'Tour &amp; Cruise '},
{id: 913, pid: 853, level: 3, name: 'Africa'},
{id: 914, pid: 913, level: 4, name: 'Egypt'},
      ]
    });

    setItemsQuoteItineraryDetails([
      { day: 1, date: '2022-02-21', title: 'detail 1', textEditor: 'detail text edit 1'}, 
      { day: 2, date: '2022-02-22', title: 'detail 2', textEditor: 'detail text edit 2'}]);
    setItemsOtherInformationTKG([{title: 'other 1', textEditor: 'other text edit 1'}, {title: 'other 2', textEditor: 'other text edit 2'}]);
    setItemTermsAndConditionsTKG({termsAndConditions: "<p>test</p>"});
    //setItemTermsAndConditionsTKG({termsAndConditions: 'Test termsAndConditions'});
  }, []);

  const onChange = (value) => {
    if (current === value) {
      return;
    }
    console.log('onChange:', current);
    switch(current) {
      case 0:
        setOverviewData(value - current);
        break;
      case 1:
        if (itemsSteps.length === 4) {
          setItineraryDetailsData(value - current);
        } else {
          setOtherInformationData(value - current);
        }
        break;
      case 2:
        if (itemsSteps.length === 4) {
          setOtherInformationData(value - current);
        } else {
          setTermsAndConditionsData(value - current);
        }
      case 3:
        // save it
        setTermsAndConditionsData(value - current);
        break;
    }
  };

  const onClickBack = (event) => {
    switch(current) {
      case 1:
        if (itemsSteps.length === 4) {
          setItineraryDetailsData(-1);
        } else {
          setOtherInformationData(-1);
        }
        break;
      case 2:
        if (itemsSteps.length === 4) {
          setOtherInformationData(-1);
        } else {
          setTermsAndConditionsData(-1);
        }
      case 3:
        // save it
        setTermsAndConditionsData(-1);
        break;
    }
  };

  const onClickNext = async (event) => {
    switch(current) {
      case 0:
        setOverviewData(1);
        break;
      case 1:
        if (itemsSteps.length === 4) {
          setItineraryDetailsData(1);
        } else {
          setOtherInformationData(1);
        }
        break;
      case 2:
        if (itemsSteps.length === 4) {
          setOtherInformationData(1);
        } else {
          setTermsAndConditionsData(1);
        }
      case 3:
        // save it
        setTermsAndConditionsData(1);
        break;
    }
  };
  const setOverviewData = async (value) => {
    try {
      const productPostOverviewData = await formProductPostOverviewTKG.validateFields();
      setItemProductPostOverviewTKG(productPostOverviewData);
      console.log('form ProductPostOverviewTKG productPostOverviewData', productPostOverviewData);
      setCurrent(current + value);
    } catch (ex) {
      console.log('Validate ProductPostOverviewTKG Failed:', ex);
    }
  }

  const setItineraryDetailsData = async (value) => {
    try {      
      const quoteTourItineraryDetailsData = await formQuoteItineraryDetailsTKG.validateFields();
      console.log('form quoteTourItineraryDetailsData', quoteTourItineraryDetailsData);
      setItemsQuoteItineraryDetails(quoteTourItineraryDetailsData.tourItineraryDetails);
      
      quoteTourItineraryDetailsData.tourItineraryDetails.forEach(item => {
        if (item.checkinCheckout && item.checkinCheckout.length === 2) {
          item.checkin = item.checkinCheckout[0].format("YYYY-MM-DD HH:mm");
          item.checkout = item.checkinCheckout[1].format("YYYY-MM-DD HH:mm");
        }
      });
      
      setCurrent(current + value);
    } catch (ex) {
      console.log('Validate formQuoteItineraryDetailsTKG Failed:', ex);
    }
  }

  const setOtherInformationData = async (value) => {
    try {
      const otherInformationData = await formOtherInformationTKG.validateFields();
      console.log('form otherInformationData', otherInformationData);
      setItemsOtherInformationTKG(otherInformationData.otherInformations);
      setCurrent(current + value);
    } catch (ex) {
      console.log('Validate formOtherInformationTKG Failed:', ex);
    }
  }

  const setTermsAndConditionsData = async (value) => {
    try {
      const termsAndConditionsData = await formTermsAndConditionsTKG.validateFields();
      console.log('form termsAndConditionsData', termsAndConditionsData);
      setItemTermsAndConditionsTKG(termsAndConditionsData);
      if (value > 0) {
        //save data
      } else {
        setCurrent(current + value);
      }
    } catch (ex) {
      console.log('Validate formTermsAndConditionsTKG Failed:', ex);
    }
  }

  const onProductTypeChange = async(valueItem) => {
    //const hideDetails = ['accommodation', 'visa', 'transfer', 'insurance', 'flight', 'other-service'];
    
    const cloneSteps = [...steps];
    if (productTypeItem.type !== 'itinerary' && valueItem.type === 'itinerary') {
      setItineraryDetailsText(cloneSteps, valueItem.name);
      setItemsSteps(cloneSteps);
    } else if (productTypeItem.type === 'itinerary' && valueItem.type === 'itinerary') {
      setItineraryDetailsText(itemsSteps, valueItem.name);
    } else if (productTypeItem.type === 'itinerary' && valueItem.type !== 'itinerary') {
      itemsSteps.splice(1, 1);
    }

    setProductTypeItem(valueItem);
  };

  const setItineraryDetailsText = (items, name) => {
    const detailText = " Itinerary Details";
    const descriptionText = " Itinerary Description";
    items[1].title = name + detailText;
    items[1].description = name + descriptionText;
  }

  return (
    <> 
    <Button onClick={() => showModal()}>Edit Product Post</Button>
    <ModalFormTKG title='Edit Product Post' open={isModalOpen} onClose={hideModal} footer={<Space>
      { current > 0 && <Button type="primary" className="btnTKGAction" onClick={onClickBack} icon={<DoubleLeftOutlined/>} >Back</Button> }
      <Button type="primary" className="btnTKGAction" onClick={onClickNext} icon={<DoubleRightOutlined/>} > { current === itemsSteps.length - 1 ? 'Finish' : 'Next'} </Button>
      </Space>}>
      <StepsTKG current={current}
                  onChange={onChange}
                  items={itemsSteps} ></StepsTKG>
      { current === 0 && 
      <ProductPostOverviewTKG form={formProductPostOverviewTKG} item={itemProductPostOverviewTKG} productTypeItem={productTypeItem} data={data} onProductTypeChange={onProductTypeChange}></ProductPostOverviewTKG>}
      { current === 1 && itemsSteps.length === 4 &&
      <QuoteItineraryDetailsTKG form={formQuoteItineraryDetailsTKG} formListName='tourItineraryDetails' items={itemsQuoteItineraryDetails} data={data} enabledDateField={true} productTypeItem={productTypeItem}></QuoteItineraryDetailsTKG>}
      { current === itemsSteps.length - 2 && 
      <OtherInformationTKG form={formOtherInformationTKG} items={itemsOtherInformationTKG}></OtherInformationTKG>}
      { current === itemsSteps.length - 1 && 
      <TermsAndConditionsTKG form={formTermsAndConditionsTKG} item={itemTermsAndConditionsTKG}></TermsAndConditionsTKG>}     
      </ModalFormTKG>
    </>
)};

export default EditProductPost;
