import React, { useState } from 'react';
import {
    EditOutlined, SearchOutlined, PlusOutlined, DeleteOutlined, FormOutlined, CloseOutlined, ClearOutlined, ReloadOutlined, FolderOutlined, ImportOutlined, ExportOutlined, ContactsOutlined, UploadOutlined, LikeOutlined, DownloadOutlined, CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, PlusCircleOutlined, DoubleRightOutlined, DoubleLeftOutlined, TeamOutlined, MoreOutlined, UpSquareOutlined, CodeOutlined
} from '@ant-design/icons';
import { Button, Card, Collapse, Divider, Drawer, Tooltip, Row, Col, Typography, Space } from 'antd';
// import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const { Panel } = Collapse;

const { Text } = Typography;

const dataButton = [
    { key: '1', value: 'Create', label: 'Create', icon: <PlusOutlined />, class: 'btnTKGAction', iconText: 'PlusOutlined' },
    { key: '2', value: 'AddNew', label: 'Add New', icon: <PlusCircleOutlined />, class: 'btnTKGAction', iconText: 'PlusCircleOutlined' },
    { key: '3', value: 'Edit', label: 'Edit', icon: <FormOutlined />, class: 'btnTKGAction', iconText: 'FormOutlined' },
    { key: '4', value: 'Update', label: 'Update', icon: '', class: 'btnTKGAction', iconText: '' },
    { key: '5', value: 'Delete', label: 'Delete', icon: <DeleteOutlined />, class: 'btnTKGDeny', iconText: 'DeleteOutlined' },
    { key: '6', value: 'Submit', label: 'Submit', icon: '', class: 'btnTKGAction', iconText: '' },
    { key: '7', value: 'View', label: 'View', icon: <FolderOutlined />, class: 'btnTKGAction', iconText: 'FolderOutlined' },
    { key: '8', value: 'Next', label: 'Next', icon: <DoubleRightOutlined />, class: 'btnTKGAction', iconText: 'DoubleRightOutlined' },
    { key: '9', value: 'Search', label: 'Search', icon: <SearchOutlined />, class: 'btnTKGAction', iconText: 'SearchOutlined' },
    // { key: '10', value: 'Active', label: 'Active', icon: '', class: 'btnTKGAction' },
    // { key: '11', value: 'Deactive', label: 'Deactive', icon: '', class: 'btnTKGDeny' },
    { key: '12', value: 'Clear', label: 'Clear', icon: <ClearOutlined />, class: 'btnTKGCancel', iconText: 'ClearOutlined' },
    { key: '13', value: 'Cancel', label: 'Cancel', icon: '', class: 'btnTKGCancel', iconText: '' },
    { key: '14', value: 'Reset', label: 'Reset', icon: <ReloadOutlined />, class: 'btnTKGAction', iconText: 'ReloadOutlined' },
    { key: '15', value: 'Upgrade', label: 'Upgrade', icon: <UpSquareOutlined />, class: 'btnTKGAction', iconText: 'UpSquareOutlined' },
    { key: '16', value: 'Download', label: 'Download', icon: <DownloadOutlined />, class: 'btnTKGAction', iconText: 'DownloadOutlined' },
    { key: '17', value: 'Upload', label: 'Upload', icon: <UploadOutlined />, class: 'btnTKGAction', iconText: 'UploadOutlined' },
    { key: '18', value: 'Import', label: 'Import', icon: <ImportOutlined />, class: 'btnTKGAction', iconText: 'ImportOutlined' },
    { key: '19', value: 'Export', label: 'Export', icon: <ExportOutlined />, class: 'btnTKGAction', iconText: 'ExportOutlined' },
    { key: '20', value: 'ContactUs', label: 'Contact Us', icon: <ContactsOutlined />, class: 'btnTKGAction', iconText: 'ContactsOutlined' },
    { key: '21', value: 'BookNow', label: 'Book Now', icon: '', class: 'btnTKGAction', iconText: '' },
    { key: '22', value: 'Enquiry', label: 'Enquiry', icon: '', class: 'btnTKGAction', iconText: '' },
    { key: '23', value: 'ViewMore', label: 'View More', icon: <MoreOutlined />, class: 'btnTKGAction', iconText: 'MoreOutlined' },
    { key: '24', value: 'Login', label: 'Login', icon: <TeamOutlined />, class: 'btnTKGAction', iconText: 'TeamOutlined' },
    { key: '25', value: 'ForgotPassword', label: 'Forgot Password', icon: <QuestionCircleOutlined />, class: 'btnTKGAction', iconText: 'QuestionCircleOutlined' },
    { key: '26', value: 'Subcribe', label: 'Subcribe', icon: <LikeOutlined />, class: 'btnTKGAction', iconText: 'LikeOutlined' },
    { key: '27', value: 'Previous', label: 'Previous', icon: <DoubleLeftOutlined />, class: 'btnTKGAction', iconText: 'DoubleLeftOutlined' },
    { key: '28', value: 'Yes', label: 'Yes', icon: <CheckCircleOutlined />, class: 'btnTKGAction', iconText: 'CheckCircleOutlined' },
    { key: '29', value: 'No', label: 'No', icon: <CloseCircleOutlined />, class: 'btnTKGCancel', iconText: 'CloseCircleOutlined' },
];

const ListButton = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [buttonName, setButtonName] = useState('');
    const [buttonClass, setButtonClass] = useState('');
    const [buttonIcon, setButtonIcon] = useState('');
    const [buttonIconText, setButtonIconText] = useState('');
    const [dataBtn, setDataBtn] = useState([]);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };
    const showCodeBTN = (key) => {
        const found = dataButton.find(obj => {
            return obj.key === key;
        });

        if (found) {
            setButtonName(found.label);
            setButtonClass(found.class);
            setButtonIcon(found.icon);
            setButtonIconText(found.iconText);
            setOpenDrawer(true);
        }

    }
    
    const ShowIcon = buttonIconText ? `icon={<${buttonIconText}/>}` : "";

    const codeStringNormal = `
    import React, { useState } from 'react';
    import { Button } from 'antd';
    import { ${buttonIconText} } from '@ant-design/icons';

    const Button = () => (
            <Button type="primary" className="${buttonClass}" ${ShowIcon}>${buttonName}</Button>
    );

    export default Button;
    `

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="List Button" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        {dataButton.map((data) => (
                                <Col span={6} key={data.key} xs={24} md={6}>
                                    <Card title={`${data.label}`}
                                        extra={
                                            <Tooltip title="View Code">
                                                <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCodeBTN(data.key)} />
                                            </Tooltip>
                                        }
                                    >
                                        <Space wrap>
                                            <Button type="primary" className={data.class} icon={data.icon}>{data.label}</Button>
                                            <Button type="primary" className={data.class} icon={data.icon} ghost>{data.label}</Button>
                                            <Button type="primary" className={data.class} icon={data.icon} disabled>{data.label}</Button>
                                            <Button type="primary" className={data.class} loading >{data.label}</Button>
                                        </Space>
                                    </Card>
                                </Col>
                        ))}
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title={`Button ${buttonName}`}
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Normal</b> <EditOutlined /></Divider>
                <Button type="primary" className={buttonClass} icon={buttonIcon} >{buttonName}</Button>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {codeStringNormal}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Outline</b> <EditOutlined /></Divider>
                <Button type="primary" className={buttonClass} icon={buttonIcon} ghost>{buttonName}</Button>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`<Button type="primary" className="${buttonClass}" ${ShowIcon} ghost >${buttonName}</Button>`}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Disabled</b> <EditOutlined /></Divider>
                <Button type="primary" className={buttonClass} icon={buttonIcon} disabled>{buttonName}</Button>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`<Button type="primary" className="${buttonClass}" ${ShowIcon} disabled >${buttonName}</Button>`}
                </SyntaxHighlighter>
                <Divider orientation="left" orientationMargin="0" plain><b>Loading</b> <EditOutlined /></Divider>
                <Button type="primary" className={buttonClass} icon={buttonIcon} loading>{buttonName}</Button>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`<Button type="primary" className="${buttonClass}" ${ShowIcon} loading >${buttonName}</Button>`}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default ListButton;
