import React from 'react'
import { Row, Col } from 'antd'
import Adult from './Reservation/Adult'
import AccommodationItinerary from './Reservation/Itinerary/AccommodationItineraryTKG'
import DateItinerary from './Reservation/Itinerary/DateItineraryTKG'
import OtherInformation from './Reservation/Itinerary/OtherInformationTKG'
import PassengerDetails from './Reservation/passengerDetails'
import PassengerInvoice from './passengerInvoice'

const index = () => {

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <AccommodationItinerary />
        </Col>
        <Col span={24} md={12}>
          <DateItinerary />
        </Col>
        <Col span={24} md={12}>
          <Adult />
        </Col>
        <Col span={24} md={12}>
          <OtherInformation />
        </Col>
        <Col span={24} md={24}>
          <PassengerDetails />
        </Col>
        <Col span={24} md={24}>
          <PassengerInvoice />
        </Col>
      </Row>




    </>

  )
}

export default index