import React from 'react';
import { Form, Row, Col } from 'antd';
//import { postCreateRole, putUpdateRole, Constant } from "@tkgplatform/api";
import { InputTKG, CheckboxTKG, SelectTKG, PopoverTKG, CollapseTKG, PanelTKG, AddDynamicFieldsTKG, SetSlug, SetNameForNestedForm } from "tkg-composite-ui";

const Overview = ({form, item, data, onChangeType, parentName, callback}) => {
  //console.log('Overview item, data', item, data);
  const handleChangeName = async (e) => {
    form.setFieldValue('slug', SetSlug(e.target.value));
  };

  return (
      <Form form={form} name="formOverview" layout="vertical" 
        initialValues={item}>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item name="name" label="Name (Name of the Product Type)" rules={[
              {
                  required: true,
                  message: 'Please enter the name',
              },
              ]}>
                <InputTKG form={form} onChange={handleChangeName} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="slug" label="Slug (Automatically Generated)" >
                <InputTKG form={form} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="type" label="Type (This Product Type will have)" >
                <SelectTKG form={form} options={data?.types} fieldNames={{label: 'name', value: 'id'}} onChange={onChangeType} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="icon" label="Icon (The Associated Icon for the Product Type):" >
                <SelectTKG form={form} options={data?.icons} fieldNames={{label: 'name', value: 'id'}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasHighlight"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Highlights?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasInclusions"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Inclusions?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasVideos"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Videos?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasTags"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Tags?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasPriceFrom"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Price From?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasUploadCarouselImages"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Upload Carousel Images?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasHomePageSection"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Home Page Section?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasProductExclusiveTo"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Product Exclusive To?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasTwoForOneDealsForCompany"])} label="" valuePropName="checked">
              <CheckboxTKG>Has 2-for-1 Deals for company?</CheckboxTKG>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasSpecialOfferFor"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Special Offer for?</CheckboxTKG>
            </Form.Item>
          </Col>                  
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasIncludeTermsAndConditions"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Include Terms and Conditions?</CheckboxTKG>
            </Form.Item>
          </Col>                  
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasIncludePrivacyPolicy"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Include Privacy Policy?</CheckboxTKG>
            </Form.Item>
          </Col>                  
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasSiteAppearanceLocation"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Site Appearance Location?</CheckboxTKG>
            </Form.Item>
          </Col>                  
          <Col span={6}>
            <Form.Item name={SetNameForNestedForm([parentName, "hasAgeRange"])} label="" valuePropName="checked">
              <CheckboxTKG>Has Age Range?</CheckboxTKG>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={24}>
            <CollapseTKG >
              <PanelTKG header="Default Fields" key="1" >
                <Row gutter={[16, 16]}>
                {data.defaultFields?.map((field) => { 
                return (
                <Col key={field.name} span={6}>
                  <PopoverTKG title={field.description}>
                    <InputTKG form={form} value={field.name} readOnly={true} />
                    </PopoverTKG>
                </Col>)
                })}   
                </Row>
              </PanelTKG>
            </CollapseTKG>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
              <AddDynamicFieldsTKG form={form} data={data} parentName='schema' itemName='overviewFields'></AddDynamicFieldsTKG>
          </Col>
        </Row>
    </Form>
  );
};
export default Overview;