import React from 'react'
import { Card, Typography } from 'antd';

const { Text, Paragraph } = Typography;
const Started = () => {
  return (
    <>
      <Card >
        <h4>The first time we install, we need to run the following command:</h4>
        <Paragraph copyable style={{ display: 'flex', alignItems: 'center' }}>
          npm set registry http://3.105.136.37:4873/
        </Paragraph>
        <Paragraph copyable style={{ display: 'flex', alignItems: 'center' }}>
          npm set ca null
        </Paragraph>
        <br />
        <h4 style={{ margin: 0 }}>Add a library to your project</h4>
        <Paragraph copyable style={{ display: 'flex', alignItems: 'center' }}>
          yarn add tkg-composite-ui
        </Paragraph>
        <br />
        <h4>Before building, you need to install additional packages</h4>
        <Paragraph copyable style={{ display: 'flex', alignItems: 'center' }}>
          yarn add core-js
        </Paragraph>
      </Card>

    </>
  )
}

export default Started