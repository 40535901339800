import React, { useState } from 'react';
import { Button, Card, Collapse, Divider, Drawer, Tooltip, Row, Col, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { SwitchActiveTKG } from 'tkg-composite-ui';

const { Panel } = Collapse;

const { Text } = Typography;

const SwitchActive = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="Switch Active" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Switch Active"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                                    </Tooltip>
                                }
                            >
                                <SwitchActiveTKG checkedChildren='Active' unCheckedChildren='Deactive'
                                    activeMessage="Active Message"
                                    deactiveMessage="Deactive Message"
                                />
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title="Editor"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Simple Editor</b> <EditOutlined /></Divider>
                <Text code>checkedChildren</Text> <Text>The content to be shown when the state is checked</Text> <br />
                <Text code>unCheckedChildren</Text> <Text>The content to be shown when the state is unchecked</Text> <br />
                <Text code>isActive</Text> <Text>Determine whether the Switch is checked</Text> <br />
                <Text code>apiMethod</Text> <Text>Api</Text> <br />
                <Text code>callback</Text> <Text>Funtion callback</Text> <br />
                <Text code>activeMessage</Text> <Text>Content message active</Text> <br />
                <Text code>deactiveMessage</Text> <Text>Content message deactive</Text> <br />
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import { SwitchActiveTKG } from 'tkg-composite-ui';

    <SwitchActiveTKG id={record.id} 
        checkedChildren='Active' 
        unCheckedChildren='Deactive' 
        isActive={record.isActive} 
        apiMethod={putStatusUser} 
        callback={getUsers}         
        activeMessage={Constant.SwitchActiveTKGMessage.ActiveUser}
        deactiveMessage={Constant.SwitchActiveTKGMessage.DeactiveUser} 
    />
    `}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default SwitchActive;
