import React from 'react'
import { Col, Row } from 'antd';
import SimpleInput from './SimpleInput'
import SimpleSelect from './SimpleSelect'
import TextEditorApp from './TextEditor';
import ButtonDelete from './ButtonDelete';
import SwitchActive from './SwitchActive';
import ListButton from './ListButton';
import TableUI from './Table';
import ModalUI from './Modal';
const index = () => {
    return (
        <Row gutter={[16, 0]}>
            <Col span={24} md={24} >
                <ListButton />
            </Col>
            <Col span={24} md={12} >
                <SimpleInput />
            </Col>
            <Col span={24} md={12}>
                <SimpleSelect />
            </Col>
            <Col span={24} md={12}>
                <TextEditorApp />
            </Col>
            <Col span={24} md={12}>
                <ButtonDelete />
            </Col>
            <Col span={24} md={12}>
                <SwitchActive />
            </Col>
            <Col span={24} md={12}>
                <ModalUI />
            </Col>
            <Col span={24} md={24}>
                <TableUI />
            </Col>
        </Row>

    )
}

export default index