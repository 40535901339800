import { Form, Button, Row, Col } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
//import { postCreateRole, putUpdateRole, Constant } from "@tkgplatform/api";
import { InputTKG, CheckboxTKG, ModalFormTKG , PopoverTKG, CollapseTKG, PanelTKG, AddDynamicFieldsPriceTableConfigTKG, SetSlug, SetNameForNestedForm } from "tkg-composite-ui";

import './modalPriceTableConfig.scss';

const ModalPriceTableConfig = ({item, data, parentName, callback}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };    
  const hideModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async() => {
    try {
      const formData = await form.validateFields();
      console.log('formData', formData);
      debugger;
    } catch(ex) {
      console.log('Validate Failed:', ex);
    }
  };

  useEffect(() => {
  }, [item, data]);
  
  return (
    <div className='add-price-table-config'>     
      <Button type="primary" onClick={showModal} title="Edit Price Table Config" className="btnTKGAction" shape='circle' icon={<SettingOutlined />}></Button>  
        <ModalFormTKG title='Edit Price Table Config' open={isModalOpen} onClose={hideModal} 
          footer={<Button type="primary" className="btnTKGAction" onClick={onSubmit} > { !item.id ? 'Save' : 'Update'} </Button>} >
          <Form form={form} name="formPriceTableConfig" layout="vertical" 
                  initialValues={item}> 
              <Row gutter={[16]}>
                <Col span={24}>
                  <CollapseTKG >
                    <PanelTKG header="Default Fields" key="1" >
                      <Row gutter={[16, 16]}>
                      {data.priceTableConfigDefaultFields?.map((field) => { 
                      return (
                      <Col key={field.name} span={6}>
                        <PopoverTKG title={field.description}>
                          <InputTKG form={form} value={field.name} readOnly={true} />
                          </PopoverTKG>
                      </Col>)
                      })}   
                      </Row>
                    </PanelTKG>
                  </CollapseTKG>
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col span={6}>
                  <Form.Item name={SetNameForNestedForm([parentName, "hasSeasonal"])} label="" valuePropName="checked">
                    <CheckboxTKG>Has Seasonal?</CheckboxTKG>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={SetNameForNestedForm([parentName, "alwaysAvailable"])} label="" valuePropName="checked">
                    <CheckboxTKG>Always Available?</CheckboxTKG>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={SetNameForNestedForm([parentName, "ignoreAutoBalance"])} label="" valuePropName="checked">
                    <CheckboxTKG>Ignore Auto-balance?</CheckboxTKG>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                    <AddDynamicFieldsPriceTableConfigTKG form={form} data={data} parentName={parentName} itemName='fields'></AddDynamicFieldsPriceTableConfigTKG>
                </Col>
              </Row>
          </Form> 
        </ModalFormTKG>
    </div>
  );
};
export default ModalPriceTableConfig;