import React, { useState } from 'react';
import { Button, Card, Collapse, Divider, Drawer, Tooltip, Row, Col, Typography } from 'antd';
import { EditOutlined, CodeOutlined, CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ButtonDeleteTKG } from 'tkg-composite-ui';

const { Panel } = Collapse;

const { Text } = Typography;

const ButtonDelete = () => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const showCode = () => {
        setOpenDrawer(true);
    }
    const onClose = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <Collapse expandIconPosition='end'>
                <Panel header="Button Delete" style={{ fontWeight: 600, fontSize: 16, marginBottom: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Button Delete"
                                extra={
                                    <Tooltip title="View Code">
                                        <Button shape="circle" icon={<CodeOutlined />} onClick={() => showCode()} />
                                    </Tooltip>
                                }
                            >
                                <ButtonDeleteTKG message='test' />
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Drawer
                title="Editor"
                placement='right'
                width={window.innerWidth > 900 ? '70%' : '100%'}
                closable={false}
                onClose={onClose}
                open={openDrawer}
                extra={
                    <CloseOutlined onClick={onClose} />
                }
            >
                <Divider orientation="left" orientationMargin="0" plain><b>Button Delete Function</b> <EditOutlined /></Divider>
                <Text code>id</Text> <Text>record id</Text> <br />
                <Text code>apiMethod</Text> <Text>apiMethod</Text> <br />
                <Text code>message</Text> <Text>Show message content</Text> <br />
                <Text code>callback</Text> <Text>Function call back</Text>
                <SyntaxHighlighter language="javascript" style={docco}>
                    {`
    import {ButtonDeleteTKG}  from 'tkg-composite-ui';

    <ButtonDeleteTKG id={record.id} apiMethod={deleteRole} message={Constant.ButtonDeleteTKGMessage.Role} callback={getRoles}>
    `}
                </SyntaxHighlighter>
            </Drawer>
        </>
    );
}

export default ButtonDelete;
