import React from 'react'
import styled from 'styled-components'
import { Card } from 'antd'

const CardStyle = styled(Card)`
.ant-card-head {
    background: #e3e6ef;
}
// .ant-card-body {
//     padding: 0 1rem 1rem 1rem;
// }
`

const CardTKG = props => {

    const propOverwrite = { ...props }
    return (
        <CardStyle  {...propOverwrite} />
    )
}


export default CardTKG